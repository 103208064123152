import { BRANCH_TYPE, FORM_CONSTANTS, INSTALLER_ENTRIES, TENANT_ID } from 'global/constants';
import {
  isInstallerRegisteringForBranch,
  isRegisteringBranchGuest,
  doesInstallerHaveBranch,
  isBranchGuest,
  isRegisteringInstallerGuestOnly,
  isRegisteredInstallerOnlyMain,
  isRegisteringInstallerOnlyMain,
} from 'utils/InstallerUtils';
import { getTenantId } from 'utils/tenantUtil';

export const VALIDATION_CONDITIONS = {
  CONDITION: 'condition',
  AND: 'and',
  OR: 'or',
  NOT: 'not',
  VALUE: 'value',
};

export const onlyHilfsbetrieb = {
  type: VALIDATION_CONDITIONS.CONDITION,
  evaluate: (validationConfig) => {
    return validationConfig.isOnlyHilfsbetrieb === true;
  },
};

export const onlyNE = {
  type: VALIDATION_CONDITIONS.CONDITION,
  evaluate: (validationConfig) => {
    return validationConfig.form === FORM_CONSTANTS.NE;
  },
};
export const onlyDEB = {
  type: VALIDATION_CONDITIONS.CONDITION,
  evaluate: (validationConfig) => {
    return validationConfig.form === FORM_CONSTANTS.DEB;
  },
};
export const never = {
  type: VALIDATION_CONDITIONS.VALUE,
  evaluate: () => {
    return false;
  },
};
export const always = {
  type: VALIDATION_CONDITIONS.VALUE,
  evaluate: () => {
    return true;
  },
};
export const onlyBAG = {
  type: VALIDATION_CONDITIONS.CONDITION,
  evaluate: () => {
    return getTenantId() === TENANT_ID.BAG;
  },
};
export const onlyAVA = {
  type: VALIDATION_CONDITIONS.CONDITION,
  evaluate: () => {
    return getTenantId() === TENANT_ID.AVA;
  },
};

export const onlyGuestNE = {
  type: VALIDATION_CONDITIONS.CONDITION,
  evaluate: (validationConfig) => {
    return isRegisteringInstallerGuestOnly(validationConfig.branchSelectionValues);
  },
};
export const onlyGuestDEB = {
  type: VALIDATION_CONDITIONS.CONDITION,
  evaluate: (validationConfig) => {
    return validationConfig.installerStatusSummary.installerEntry === INSTALLER_ENTRIES.GUEST_ONLY;
  },
};
export const onlyMainDEB = {
  type: VALIDATION_CONDITIONS.CONDITION,
  evaluate: (validationConfig) => {
    return isRegisteredInstallerOnlyMain(validationConfig.installerStatusSummary);
  },
};
export const MainDEB = {
  type: VALIDATION_CONDITIONS.CONDITION,
  evaluate: (validationConfig) => {
    return (
      (doesInstallerHaveBranch(BRANCH_TYPE.ENERGY, validationConfig.installerStatusSummary) &&
        !isBranchGuest(validationConfig.installerStatusSummary, BRANCH_TYPE.ENERGY)) ||
      (doesInstallerHaveBranch(BRANCH_TYPE.GAS, validationConfig.installerStatusSummary) &&
        !isBranchGuest(validationConfig.installerStatusSummary, BRANCH_TYPE.GAS))
    );
  },
};
export const onlyMainNE = {
  type: VALIDATION_CONDITIONS.CONDITION,
  evaluate: (validationConfig) => {
    return isRegisteringInstallerOnlyMain(validationConfig.branchSelectionValues);
  },
};

export const MainNE = {
  type: VALIDATION_CONDITIONS.CONDITION,
  evaluate: (validationConfig) => {
    return (
      (isInstallerRegisteringForBranch(
        BRANCH_TYPE.ENERGY,
        validationConfig.branchSelectionValues
      ) &&
        !isRegisteringBranchGuest(BRANCH_TYPE.ENERGY, validationConfig.branchSelectionValues)) ||
      (isInstallerRegisteringForBranch(BRANCH_TYPE.GAS, validationConfig.branchSelectionValues) &&
        !isRegisteringBranchGuest(BRANCH_TYPE.GAS, validationConfig.branchSelectionValues))
    );
  },
};
export const isRegisteredInstallerGasGuest = {
  type: VALIDATION_CONDITIONS.CONDITION,
  evaluate: (validationConfig) => {
    return (
      doesInstallerHaveBranch(BRANCH_TYPE.GAS, validationConfig.installerStatusSummary) &&
      isBranchGuest(validationConfig.installerStatusSummary, BRANCH_TYPE.GAS)
    );
  },
};
export const isRegisteredInstallerEnergyGuest = {
  type: VALIDATION_CONDITIONS.CONDITION,
  evaluate: (validationConfig) => {
    return (
      doesInstallerHaveBranch(BRANCH_TYPE.ENERGY, validationConfig.installerStatusSummary) &&
      isBranchGuest(validationConfig.installerStatusSummary, BRANCH_TYPE.ENERGY)
    );
  },
};

export const isRegisteringInstallerGasGuest = {
  type: VALIDATION_CONDITIONS.CONDITION,
  evaluate: (validationConfig) => {
    return (
      isInstallerRegisteringForBranch(BRANCH_TYPE.GAS, validationConfig.branchSelectionValues) &&
      isRegisteringBranchGuest(BRANCH_TYPE.GAS, validationConfig.branchSelectionValues)
    );
  },
};
export const isRegisteringInstallerEnergyGuest = {
  type: VALIDATION_CONDITIONS.CONDITION,
  evaluate: (validationConfig) => {
    return (
      isInstallerRegisteringForBranch(BRANCH_TYPE.ENERGY, validationConfig.branchSelectionValues) &&
      isRegisteringBranchGuest(BRANCH_TYPE.ENERGY, validationConfig.branchSelectionValues)
    );
  },
};
export const isRegisteringExpertOwner = {
  type: VALIDATION_CONDITIONS.CONDITION,
  evaluate: (validationConfig) => {
    return validationConfig.expert.isExpertOwner;
  },
};
export const onlyEnergy = {
  type: VALIDATION_CONDITIONS.CONDITION,
  evaluate: (validationConfig) => {
    return validationConfig.branch === BRANCH_TYPE.ENERGY;
  },
};
export const onlyGas = {
  type: VALIDATION_CONDITIONS.CONDITION,
  evaluate: (validationConfig) => {
    return validationConfig.branch === BRANCH_TYPE.GAS;
  },
};
export const masterTo1997Energy = {
  type: VALIDATION_CONDITIONS.CONDITION,
  evaluate: (validationConfig) => {
    return validationConfig.firstQualificationEnergy === 'masterTo1997Energy';
  },
};
export const masterFrom1998To2003Energy = {
  type: VALIDATION_CONDITIONS.CONDITION,
  evaluate: (validationConfig) => {
    return validationConfig.firstQualificationEnergy === 'masterFrom1998To2003Energy';
  },
};
export const masterFrom2004Energy = {
  type: VALIDATION_CONDITIONS.CONDITION,
  evaluate: (validationConfig) => {
    return validationConfig.firstQualificationEnergy === 'masterFrom2004Energy';
  },
};
export const masterTo1997Gas = {
  type: VALIDATION_CONDITIONS.CONDITION,
  evaluate: (validationConfig) => {
    return validationConfig.firstQualificationGas === 'masterTo1997Gas';
  },
};
export const masterFrom1998To2003Gas = {
  type: VALIDATION_CONDITIONS.CONDITION,
  evaluate: (validationConfig) => {
    return validationConfig.firstQualificationGas === 'masterFrom1998To2003Gas';
  },
};
export const masterFrom2004Gas = {
  type: VALIDATION_CONDITIONS.CONDITION,
  evaluate: (validationConfig) => {
    return validationConfig.firstQualificationGas === 'masterFrom2004Gas';
  },
};
export const technicalHighSchool = {
  type: VALIDATION_CONDITIONS.CONDITION,
  evaluate: (validationConfig) => {
    return validationConfig.firstQualificationGas === 'technicalHighSchool';
  },
};
export const engineer = {
  type: VALIDATION_CONDITIONS.CONDITION,
  evaluate: (validationConfig) => {
    return validationConfig.firstQualificationGas === 'engineer';
  },
};
export const otherQualificationEnergy = {
  type: VALIDATION_CONDITIONS.CONDITION,
  evaluate: (validationConfig) => {
    return validationConfig.firstQualificationEnergy === 'otherQualificationEnergy';
  },
};

export const otherQualificationGas = {
  type: VALIDATION_CONDITIONS.CONDITION,
  evaluate: (validationConfig) => {
    return validationConfig.firstQualificationGas === 'otherQualificationGas';
  },
};

export const expertNotOwner = {
  type: VALIDATION_CONDITIONS.CONDITION,
  evaluate: (validationConfig) => {
    return validationConfig.expert.isExpertOwner != true;
  },
};

export const handleFieldRendering = (triggers, notTriggers) => {
  return {
    type: VALIDATION_CONDITIONS.AND,
    conditions: [
      {
        type: VALIDATION_CONDITIONS.NOT,
        condition: {
          type: VALIDATION_CONDITIONS.CONDITION,
          evaluate: (validationConfig) => {
            return validationConfig.expertQualificationsTriggers.some((trigger) =>
              notTriggers.includes(trigger)
            );
          },
        },
      },
      {
        type: triggers.length >= 1 ? VALIDATION_CONDITIONS.CONDITION : VALIDATION_CONDITIONS.VALUE,
        evaluate: (validationConfig) => {
          return triggers.length >= 1
            ? validationConfig.expertQualificationsTriggers.some((trigger) =>
                triggers.includes(trigger)
              )
            : true;
        },
      },
    ],
  };
};
