import * as Yup from 'yup';
import { getTenantId } from 'utils/tenantUtil';
import { YDate, YFutureDate, YPastDate } from 'utils/YupUtils';
import {
  EIV_STATUS,
  EXPERT_EDITING_STATUS,
  TENANT_ID,
  EIV_CHAR_LENGTH,
  VALIDATION_CONSTANTS,
} from 'global/constants';
import { NO_SECOND_QUALIFICATION } from 'global/qualifications';

export const YExpert = Yup.object().shape({
  id: Yup.number().required(),
  lastName: Yup.string()
    .min(2, VALIDATION_CONSTANTS.TOO_SHORT)
    .max(EIV_CHAR_LENGTH.EXPERT_NAME, VALIDATION_CONSTANTS.TOO_LONG)
    .required(VALIDATION_CONSTANTS.MANDATORY),
  birthdate: Yup.string().when(['statusGas', 'statusEnergy'], {
    is: (statusGas, statusEnergy) =>
      statusGas === EIV_STATUS.NEW || statusEnergy === EIV_STATUS.NEW,
    then:
      getTenantId() === TENANT_ID.BAG
        ? YPastDate.required(VALIDATION_CONSTANTS.MANDATORY)
        : Yup.string(),
  }),
  firstName: Yup.string()
    .min(2, VALIDATION_CONSTANTS.TOO_SHORT)
    .max(EIV_CHAR_LENGTH.EXPERT_NAME, VALIDATION_CONSTANTS.TOO_LONG)
    .required(VALIDATION_CONSTANTS.MANDATORY),
  isMainEnergy: Yup.boolean(),
  isMainGas: Yup.boolean(),
  expertGasValidityDate: Yup.string().when(['isMainGas', 'editingStatusGas'], {
    is: (isMainGas, editingStatusGas) => {
      return isMainGas === false && Object.values(EXPERT_EDITING_STATUS).includes(editingStatusGas);
    },
    then: YFutureDate.required(VALIDATION_CONSTANTS.MANDATORY),
  }),
  expertEnergyValidityDate: Yup.string().when(['isMainEnergy', 'editingStatusEnergy'], {
    is: (isMainEnergy, editingStatusEnergy) => {
      return (
        isMainEnergy === false && Object.values(EXPERT_EDITING_STATUS).includes(editingStatusEnergy)
      );
    },
    then: YFutureDate.required(VALIDATION_CONSTANTS.MANDATORY),
  }),
  deletionDateEnergy: YDate,
  deletionDateGas: YDate,
  firstQualificationEnergy: Yup.string().when(['isMainEnergy', 'statusEnergy'], {
    is: (isMainEnergy, statusEnergy) => isMainEnergy && statusEnergy === EIV_STATUS.NEW,
    then: (validation) => validation.required(),
  }),
  firstQualificationGas: Yup.string().when(['isMainGas', 'statusGas'], {
    is: (isMainGas, statusGas) => isMainGas && statusGas === EIV_STATUS.NEW,
    then: (validation) => validation.required(),
  }),
  secondQualificationEnergy: Yup.string().when(
    ['isMainEnergy', 'statusEnergy', 'firstQualificationEnergy'],
    {
      is: (isMainEnergy, statusEnergy, firstQualificationEnergy) =>
        isMainEnergy &&
        statusEnergy === EIV_STATUS.NEW &&
        !NO_SECOND_QUALIFICATION.includes(firstQualificationEnergy),
      then: (validation) => validation.required(),
    }
  ),
  secondQualificationGas: Yup.string().when(['isMainGas', 'statusGas', 'firstQualificationGas'], {
    is: (isMainGas, statusGas, firstQualificationGas) =>
      isMainGas &&
      statusGas === EIV_STATUS.NEW &&
      !NO_SECOND_QUALIFICATION.includes(firstQualificationGas),
    then: (validation) => validation.required(),
  }),
  isExpertOwner: Yup.bool().when(['isMainGas', 'isMainEnergy'], {
    is: (isMainGas, isMainEnergy) => isMainGas || isMainEnergy,
    then: (validation) => validation.required(),
  }),
  deletionReasonGas: Yup.string()
    .min(2, VALIDATION_CONSTANTS.TOO_SHORT)
    .max(EIV_CHAR_LENGTH.STANDARD_TEXT, VALIDATION_CONSTANTS.TOO_LONG),
  deletionReasonEnergy: Yup.string()
    .min(2, VALIDATION_CONSTANTS.TOO_SHORT)
    .max(EIV_CHAR_LENGTH.STANDARD_TEXT, VALIDATION_CONSTANTS.TOO_LONG),
});
