import {
  onlyEnergy,
  onlyGas,
  engineer,
  masterFrom1998To2003Energy,
  masterFrom1998To2003Gas,
  masterFrom2004Energy,
  masterFrom2004Gas,
  masterTo1997Energy,
  masterTo1997Gas,
  onlyBAG,
  technicalHighSchool,
  VALIDATION_CONDITIONS,
  otherQualificationGas,
  otherQualificationEnergy,
} from 'global/renderingValidationConstants';
import React from 'react';
export const FIRST_QUALIFICATIONS = {
  masterTo1997Energy: {
    label: 'Meisterprüfung bis einschließlich 1997',
    value: 'masterTo1997Energy',
    whenShouldFieldRender: onlyEnergy,
    triggerID: 0,
  },
  masterTo1997Gas: {
    label: 'Meistertitel (Prüfung vor 1998)',
    value: 'masterTo1997Gas',
    whenShouldFieldRender: onlyGas,
    triggerID: 0,
  },
  masterFrom1998To2003Energy: {
    label: 'Meisterprüfung 1998 bis einschließlich 2003',
    value: 'masterFrom1998To2003Energy',
    whenShouldFieldRender: onlyEnergy,
    triggerID: 0,
  },
  masterFrom1998To2003Gas: {
    label: 'Meistertitel (Prüfung 1998 – 2003)',
    value: 'masterFrom1998To2003Gas',
    whenShouldFieldRender: onlyGas,
    triggerID: 0,
  },
  masterFrom2004Energy: {
    label: 'Meisterprüfung ab 2004',
    value: 'masterFrom2004Energy',
    whenShouldFieldRender: onlyEnergy,
    triggerID: 0,
  },
  masterFrom2004Gas: {
    label: 'Meistertitel (Prüfung ab 2003)',
    value: 'masterFrom2004Gas',
    whenShouldFieldRender: onlyGas,
    triggerID: 0,
  },
  technicalHighSchool: {
    label: 'Ausbildung an einer staatlichen oder anerkannten Fachschule für Technik',
    value: 'technicalHighSchool',
    whenShouldFieldRender: onlyGas,
    triggerID: 0,
  },
  engineer: {
    label: 'Diplom-Ingenieur (FH, TU), Studienabschluss Bachelor oder Master of Science',
    value: 'engineer',
    whenShouldFieldRender: onlyGas,
    triggerID: 68,
  },
  otherQualificationEnergy: {
    label: 'Andere Qualifikationen',
    value: 'otherQualificationEnergy',
    whenShouldFieldRender: onlyEnergy,
    triggerID: 53,
  },
  otherQualificationGas: {
    label: 'Weitere Qualifikationen',
    value: 'otherQualificationGas',
    whenShouldFieldRender: onlyGas,
    triggerID: 69,
  },
};

export const NO_SECOND_QUALIFICATION = [
  FIRST_QUALIFICATIONS.engineer.value,
  FIRST_QUALIFICATIONS.otherQualificationGas.value,
  FIRST_QUALIFICATIONS.otherQualificationEnergy.value,
];

export const SECOND_QUALIFICATIONS = {
  masterTo1997Electrician: {
    label: 'Elektroinstallateur',
    value: 'masterTo1997Electrician',
    whenShouldFieldRender: masterTo1997Energy,
    triggerID: 42,
  },
  masterTo1997OtherElectrical: {
    label: 'andere Meisterprüfungen im Elektrohandwerk',
    value: 'masterTo1997OtherElectrical',
    whenShouldFieldRender: masterTo1997Energy,
    triggerID: 43,
  },
  masterFrom1998To2003Electrician: {
    label: 'Elektrotechniker / Elektroinstallateur',
    value: 'masterFrom1998To2003Electrician',
    whenShouldFieldRender: {
      type: VALIDATION_CONDITIONS.AND,
      conditions: [
        {
          type: VALIDATION_CONDITIONS.NOT,
          condition: onlyBAG,
        },
        masterFrom1998To2003Energy,
      ],
    },
    triggerID: 45,
  },
  electrician1975: {
    label: 'Elektrotechniker nach Meisterberufsbildverordnung von 1975 – Elektroinstallateur',
    value: 'electrician1975',
    whenShouldFieldRender: {
      type: VALIDATION_CONDITIONS.AND,
      conditions: [onlyBAG, masterFrom1998To2003Energy],
    },
    triggerID: 46,
  },
  electromechanic1976: {
    label: 'Elektrotechniker nach Meisterberufsbildverordnung von 1976 – Elektromechaniker',
    value: 'electromechanic1976',
    whenShouldFieldRender: {
      type: VALIDATION_CONDITIONS.AND,
      conditions: [onlyBAG, masterFrom1998To2003Energy],
    },
    triggerID: 47,
  },
  telecomElectronics1994: {
    label:
      'Elektrotechniker nach Meisterberufsbildverordnung von 1994 – Fernmeldeanlagenelektroniker',
    value: 'telecomElectronics1994',
    whenShouldFieldRender: {
      type: VALIDATION_CONDITIONS.AND,
      conditions: [onlyBAG, masterFrom1998To2003Energy],
    },
    triggerID: 48,
  },
  masterFrom1998To2003OtherElectrical: {
    label: 'andere Meisterprüfungen im Elektrohandwerk',
    value: 'masterFrom1998To2003OtherElectrical',
    whenShouldFieldRender: masterFrom1998To2003Energy,
    triggerID: 49,
  },
  masterFrom2004Safety50PlusEnergy: {
    label: 'im Sicherheitsschein erzielte Punkte ≥ 50 % erreicht',
    value: 'masterFrom2004Safety50PlusEnergy',
    whenShouldFieldRender: masterFrom2004Energy,
    triggerID: 52,
  },
  masterFrom2004Safety50MinusEnergy: {
    label: 'im Sicherheitsschein erzielte Punkte < 50 % erreicht',
    value: 'masterFrom2004Safety50MinusEnergy',
    whenShouldFieldRender: masterFrom2004Energy,
    triggerID: 51,
  },
  masterTo1997Gas: {
    label: 'im Gas- und Wasser-Installateurhandwerk',
    value: 'masterTo1997Gas',
    whenShouldFieldRender: masterTo1997Gas,
    triggerID: 57,
  },
  masterTo1997Heating: {
    label: 'im Zentralheizungs- und Lüftungsbauhandwerk',
    value: 'masterTo1997Heating',
    whenShouldFieldRender: masterTo1997Gas,
    triggerID: 58,
  },
  masterFrom1998To2003GasWater: {
    label:
      'im Installateur- und Heizungsbauerhandwerk nach der Prüfungsverordnung für das Gas- und Wasserinstallateurhandwerk',
    value: 'masterFrom1998To2003GasWater',
    whenShouldFieldRender: masterFrom1998To2003Gas,
    triggerID: 60,
  },
  masterFrom1998To2003HeatingVentilation: {
    label:
      'im Installateur- und Heizungsbauerhandwerk nach der Prüfungsverordnung für Zentralheizungs- und Lüftungsbauer',
    value: 'masterFrom1998To2003HeatingVentilation',
    whenShouldFieldRender: masterFrom1998To2003Gas,
    triggerID: 61,
  },
  masterFrom2004Safety50Plus: {
    label:
      'im Installateur- und Heizungsbauerhandwerk mit Bescheinigung zum Fach Sicherheits- und Instandhaltungstechnik (mit ≥ 50 P)',
    value: 'masterFrom2004Safety50Plus',
    whenShouldFieldRender: masterFrom2004Gas,
    triggerID: 63,
  },
  masterFrom2004Safety50Minus: {
    label:
      'im Installateur- und Heizungsbauerhandwerk mit Bescheinigung zum Fach Sicherheits- und Instandhaltungstechnik (mit < 50 P)',
    value: 'masterFrom2004Safety50Minus',
    whenShouldFieldRender: masterFrom2004Gas,
    triggerID: 64,
  },
  technicalSchoolSanitary: {
    label: 'Fachrichtung Sanitärtechnik, Versorgungstechnik',
    value: 'technicalSchoolSanitary',
    whenShouldFieldRender: technicalHighSchool,
    triggerID: 66,
  },
  technicalSchoolClimate: {
    label: 'Fachrichtung Klima- und Lüftungstechnik, Heizungs- und Lüftungstechnik',
    value: 'technicalSchoolClimate',
    whenShouldFieldRender: technicalHighSchool,
    triggerID: 67,
  },
  engineerDegree: {
    label: (
      <div>
        Fachrichtungen:
        <br />
        <ul>
          <li>Versorgungstechnik</li>
          <li>Betriebs- und Versorgungstechnik</li>
          <li>Energie- und Wärmetechnik</li>
          <li>Maschinenbau</li>
          <li>Produktionstechnik</li>
          <li>Verfahrenstechnik</li>
          <li>Schiffmaschinenbau und Schiffbetriebstechnik</li>
          <li>Sanitärtechnik (HLS-Technik)</li>
        </ul>
      </div>
    ),
    value: 'engineerDegree',
    whenShouldFieldRender: engineer,
    triggerID: 68,
  },
  otherQualificationGas: {
    label: (
      <div>
        <ul>
          <li>
            Berufsabschluss aus der ehemaligen DDR Volkseigener Meister nur für Volkseigene Betriebe
            zuständig
          </li>
          <li>Grenzüberschreitende Tätigkeit von Gasinstallateuren</li>
          <li>Ausübungsberechtigungen und Ausnahmefall gem. §§4,7,8,9 HWO</li>
          <li>Industriebetrieb</li>
          <li>Wohnungsbauergesellschaft</li>
        </ul>
      </div>
    ),
    value: 'otherQualificationGas',
    whenShouldFieldRender: otherQualificationGas,
    triggerID: 69,
  },
  otherQualificationEnergy: {
    label: (
      <div>
        Andere Qualifikationen sind:
        <ul>
          <li>Meisterprüfung als Installateur und Heizungsbauer</li>
          <li>sog. G6-Geselle (Altgesellen) aus dem Elektrohandwerk</li>
          <li>Anerkennung(en) von Abschlüssen nach §7(2) HwO</li>
          <li>
            der Fachrichtung Elektrotechnik (z.B. Industriemeister, Ingenieur(e), Master, Bachelor,
            Techniker)
          </li>
          <li>andere Ausübungsberechtigungen nach § 7,8,9 HwO</li>
        </ul>
      </div>
    ),
    value: 'otherQualificationEnergy',
    whenShouldFieldRender: otherQualificationEnergy,
    triggerID: 53,
  },
};
