import { useMemo } from 'react';
import * as Yup from 'yup';
import { DEB_DOCUMENTS_FIELDS, DEB_EXPERTS_DOCUMENTS_FIELDS } from './DocumentsFields';
import {
  DOCUMENT_SECTIONS_IDS,
  calculateChangedValues,
  isRegisteredInstallerOnlyHilfsbetrieb,
  shouldDebSecondPageSectionRender,
} from './DebFormSectionUtil';
import {
  BRANCH_TYPE,
  FORM_CONSTANTS,
  EIV_STATUS,
  INSTALLER_ENTRIES,
  TENANT_ID,
  VALIDATION_CONSTANTS,
  EIV_STATUS_ALLOW_RENDER,
} from 'global/constants';
import { getTenantId } from 'utils/tenantUtil';
import { isBranchGuest } from 'utils/InstallerUtils';
import { evaluateCondition } from 'global/fieldValidationFunction';
import { FIRST_QUALIFICATIONS, SECOND_QUALIFICATIONS } from 'global/qualifications';

export function useDebFormSecondPageLogic(
  valuesFirstPage,
  initialValuesFirstPage,
  installerStatusSummary,
  installer
) {
  const mainGasPossibilities = [
    INSTALLER_ENTRIES.MAIN_GAS_ONLY,
    INSTALLER_ENTRIES.MAIN_BOTH,
    INSTALLER_ENTRIES.GUEST_ENERGY_MAIN_GAS,
  ];

  const expertTriggerIdsTenant = {
    [TENANT_ID.AVA]: [1],
    [TENANT_ID.EDI]: [1],
    [TENANT_ID.SHNG]: [1],
    [TENANT_ID.BAG]: [1, 2, 3],
  };

  function calculateExpertTriggers(valuesFirstPage, initialValuesFirstPage, documentsTrigger) {
    const expertTriggers = {};
    valuesFirstPage.experts.forEach((expert) => {
      let triggers = [];
      const gasStatusesRender = [EIV_STATUS.ACTIVE, EIV_STATUS.IN_PROGRESS];
      const energyStatusesRender = [EIV_STATUS.ACTIVE, EIV_STATUS.IN_PROGRESS];
      const guestEnergy = isBranchGuest(installerStatusSummary, BRANCH_TYPE.ENERGY);
      const guestGas = isBranchGuest(installerStatusSummary, BRANCH_TYPE.GAS);
      if (expert.firstQualificationEnergy) {
        triggers.push(FIRST_QUALIFICATIONS[expert.firstQualificationEnergy].triggerID);
      }
      if (expert.firstQualificationGas) {
        triggers.push(FIRST_QUALIFICATIONS[expert.firstQualificationGas].triggerID);
      }
      if (expert.secondQualificationEnergy) {
        triggers.push(SECOND_QUALIFICATIONS[expert.secondQualificationEnergy].triggerID);
      }
      if (expert.secondQualificationGas) {
        triggers.push(SECOND_QUALIFICATIONS[expert.secondQualificationGas].triggerID);
      }
      if (expert.isExpertOwner) {
        triggers.push(40);
      }
      if (documentsTrigger.includes(12)) {
        if (gasStatusesRender.includes(expert.statusGas)) {
          if (!guestGas) {
            triggers.push(12);
          } else {
            let oldExpert = initialValuesFirstPage.experts.find((initialExpert) => {
              return initialExpert.id === expert.id;
            });
            if (
              expert.statusGas === EIV_STATUS.NEW ||
              expert.expertGasValidityDate != oldExpert.expertGasValidityDate
            ) {
              triggers.push(12);
            }
          }
        }
      }
      if (documentsTrigger.includes(11)) {
        if (energyStatusesRender.includes(expert.statusEnergy)) {
          if (!guestEnergy) {
            triggers.push(11);
          } else {
            let oldExpert = initialValuesFirstPage.experts.find((initialExpert) => {
              return initialExpert.id === expert.id;
            });
            if (
              expert.statusEnergy === EIV_STATUS.NEW ||
              expert.expertEnergyValidityDate != oldExpert.expertEnergyValidityDate
            ) {
              triggers.push(11);
            }
          }
        }
      }
      if (expert.statusGas === EIV_STATUS.NEW) {
        documentsTrigger.push(10);
        triggers.push(10);
      }
      if (expert.statusEnergy === EIV_STATUS.NEW) {
        triggers.push(9);
        documentsTrigger.push(9);
      }
      if (expert.statusGas === EIV_STATUS.DELETED) {
        triggers.push(23);
      }
      if (expert.statusEnergy === EIV_STATUS.DELETED) {
        triggers.push(24);
      }
      expertTriggers[expert.id] = triggers;
    });
    return [expertTriggers, Array.from(new Set(documentsTrigger))];
  }

  function calculateInstallerTriggersIds(changedValues) {
    const documentTriggerIds = Object.keys(changedValues).reduce((acc, key) => {
      const sectionIds = DOCUMENT_SECTIONS_IDS.filter((section) =>
        section.fields.includes(key)
      ).map((section) => section.id);
      return acc.concat(sectionIds);
    }, []);
    return Array.from(new Set(documentTriggerIds));
  }

  function updateExpertTriggersForMainGas(
    installerStatusSummary,
    documentTriggerIds,
    valuesFirstPage,
    expertTriggers
  ) {
    if (
      mainGasPossibilities.includes(installerStatusSummary.installerEntry) &&
      expertTriggerIdsTenant[getTenantId()].some((id) => documentTriggerIds.includes(id))
    ) {
      valuesFirstPage.experts.forEach((expert) => {
        if (EIV_STATUS_ALLOW_RENDER.includes(expert.statusGas)) {
          expertTriggers[expert.id] = [...expertTriggers[expert.id], 1];
        }
      });
    }
  }

  function createInitialValues(changedValues, documentTriggerIds, expertTriggers, installer) {
    const initialValues = {
      experts: changedValues.experts,
      documentsTrigger: documentTriggerIds,
      expertTrigger: expertTriggers,
      isAddressPublic: installer.zu_adressveroeff === 'Ja',
      canSendMail: installer.zu_infoversand === 'Ja',
      changes: '',
    };

    return initialValues;
  }

  function createValidationSchema(
    initialValues,
    installerStatusSummary,
    documentTriggerIds,
    expertTriggers
  ) {
    let validationObject = {};
    const evaluateConditionsConfig = {
      isOnlyHilfsbetrieb: isRegisteredInstallerOnlyHilfsbetrieb(installerStatusSummary),
      form: FORM_CONSTANTS.DEB,
    };
    DEB_DOCUMENTS_FIELDS[installerStatusSummary.installerEntry][getTenantId()].forEach(
      (section) => {
        if (
          shouldDebSecondPageSectionRender(section, documentTriggerIds, installerStatusSummary) &&
          (section.whenShouldFieldRender
            ? evaluateCondition(section.whenShouldFieldRender, evaluateConditionsConfig)
            : true)
        ) {
          validationObject[section.name] = Yup.array().when([], {
            is: () => {
              return evaluateCondition(section.isOptional, evaluateConditionsConfig);
            },
            then: Yup.array(),
            otherwise: Yup.array().min(1).required(),
          });

          initialValues[section.name] = [];
        }
      }
    );

    Object.keys(expertTriggers).map((key) => {
      let expertValidation = {};
      initialValues[key] = {};
      const expert = valuesFirstPage.experts?.find((expert) => {
        return expert.id == key;
      });
      DEB_EXPERTS_DOCUMENTS_FIELDS[installerStatusSummary.installerEntry][getTenantId()].forEach(
        (section) => {
          if (
            shouldDebSecondPageSectionRender(
              section,
              expertTriggers[key],
              installerStatusSummary,
              expert
            )
          ) {
            expertValidation = {
              ...expertValidation,
              [section.name]: Yup.array().when([], {
                is: () => evaluateCondition(section.isOptional, evaluateConditionsConfig),
                then: Yup.array(),
                otherwise: Yup.array().min(1).required(),
              }),
            };
            initialValues[key][section.name] = [];
          }
        }
      );
      validationObject[key] = Yup.object().shape(expertValidation);
    });

    const validationSchema = Yup.object().shape({
      changes: Yup.string()
        .min(2, VALIDATION_CONSTANTS.TOO_SHORT)
        .max(FORM_CONSTANTS.FREETEXT_INPUT, VALIDATION_CONSTANTS.TOO_LONG),
      isAddressPublic: Yup.bool(),
      canSendMail: Yup.bool(),
      ...validationObject,
    });

    return validationSchema;
  }

  return useMemo(() => {
    const changedValues = calculateChangedValues(valuesFirstPage, initialValuesFirstPage);
    let documentTriggerIdsOfInstallerFields = calculateInstallerTriggersIds(changedValues);

    const [expertTriggers, documentTriggerIds] = calculateExpertTriggers(
      valuesFirstPage,
      initialValuesFirstPage,
      documentTriggerIdsOfInstallerFields
    );

    updateExpertTriggersForMainGas(
      installerStatusSummary,
      documentTriggerIds,
      valuesFirstPage,
      expertTriggers
    );

    const initialValues = createInitialValues(
      changedValues,
      documentTriggerIds,
      expertTriggers,
      installer
    );
    const validationSchema = createValidationSchema(
      initialValues,
      installerStatusSummary,
      documentTriggerIds,
      expertTriggers
    );

    return {
      initialValues,
      validationSchema,
    };
  }, [valuesFirstPage, initialValuesFirstPage, installer, installerStatusSummary]);
}
