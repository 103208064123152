import { SHARED_DOCUMENTS } from 'global/SharedDocumentFields';
import { INSTALLER_ENTRIES, TENANT_ID } from 'global/constants';
import { always, expertNotOwner } from 'global/renderingValidationConstants';

export const DEB_DOCUMENTS_FIELDS = {
  [INSTALLER_ENTRIES.MAIN_ENERGY_ONLY]: {
    [TENANT_ID.AVA]: [
      { ...SHARED_DOCUMENTS[13], triggeredByGroupIds: [1, 3] },
      { ...SHARED_DOCUMENTS[14], triggeredByGroupIds: [1] },
      { ...SHARED_DOCUMENTS[17], triggeredByGroupIds: [1, 3] },
      { ...SHARED_DOCUMENTS[22], triggeredByGroupIds: [11], isOptional: always },
    ],
    [TENANT_ID.EDI]: [
      { ...SHARED_DOCUMENTS[13], triggeredByGroupIds: [1, 2, 3] },
      { ...SHARED_DOCUMENTS[17], triggeredByGroupIds: [1, 2, 3] },
      { ...SHARED_DOCUMENTS[22], triggeredByGroupIds: [11] },
    ],
    [TENANT_ID.SHNG]: [
      { ...SHARED_DOCUMENTS[13], triggeredByGroupIds: [1, 2, 3] },
      { ...SHARED_DOCUMENTS[17], triggeredByGroupIds: [1, 2, 3, 9] },
      { ...SHARED_DOCUMENTS[22], triggeredByGroupIds: [11], isOptional: always },
    ],
    [TENANT_ID.BAG]: [
      { ...SHARED_DOCUMENTS[13], triggeredByGroupIds: [1, 2, 3, 6] },
      { ...SHARED_DOCUMENTS[17], triggeredByGroupIds: [1, 2, 3, 5] },
      { ...SHARED_DOCUMENTS[18], triggeredByGroupIds: [7] },
    ],
  },
  [INSTALLER_ENTRIES.MAIN_GAS_ONLY]: {
    [TENANT_ID.AVA]: [
      { ...SHARED_DOCUMENTS[13], triggeredByGroupIds: [1, 3] },
      { ...SHARED_DOCUMENTS[14], triggeredByGroupIds: [1, 3] },
      { ...SHARED_DOCUMENTS[17], triggeredByGroupIds: [1, 3] },
    ],
    [TENANT_ID.EDI]: [
      { ...SHARED_DOCUMENTS[13], triggeredByGroupIds: [1, 3] },
      { ...SHARED_DOCUMENTS[14], triggeredByGroupIds: [1] },
      { ...SHARED_DOCUMENTS[17], triggeredByGroupIds: [1, 3] },
    ],
    [TENANT_ID.SHNG]: [
      { ...SHARED_DOCUMENTS[13], triggeredByGroupIds: [1, 3] },
      { ...SHARED_DOCUMENTS[14], triggeredByGroupIds: [1] },
      { ...SHARED_DOCUMENTS[16], triggeredByGroupIds: [1, 3] },
      { ...SHARED_DOCUMENTS[17], triggeredByGroupIds: [1, 3] },
    ],
    [TENANT_ID.BAG]: [
      { ...SHARED_DOCUMENTS[13], triggeredByGroupIds: [1, 2, 3, 6] },
      { ...SHARED_DOCUMENTS[14], triggeredByGroupIds: [1] },
      { ...SHARED_DOCUMENTS[15], triggeredByGroupIds: [1, 2, 3] },
      { ...SHARED_DOCUMENTS[16], triggeredByGroupIds: [1, 2] },
      { ...SHARED_DOCUMENTS[17], triggeredByGroupIds: [1, 2, 3, 5] },
      { ...SHARED_DOCUMENTS[18], triggeredByGroupIds: [1, 2, 7] },
    ],
  },
  [INSTALLER_ENTRIES.GUEST_ONLY]: {
    [TENANT_ID.AVA]: [
      { ...SHARED_DOCUMENTS[19], triggeredByGroupIds: [1, 2, 3, 8, 9, 10, 11, 12] },
    ],
    [TENANT_ID.EDI]: [
      { ...SHARED_DOCUMENTS[19], triggeredByGroupIds: [1, 2, 3, 8, 9, 10, 11, 12] },
    ],

    [TENANT_ID.SHNG]: [
      { ...SHARED_DOCUMENTS[19], triggeredByGroupIds: [1, 2, 3, 8, 9, 10, 11, 12] },
    ],
    [TENANT_ID.BAG]: [
      { ...SHARED_DOCUMENTS[19], triggeredByGroupIds: [1, 2, 3, 8, 9, 10, 11, 12] },
    ],
  },
  [INSTALLER_ENTRIES.MAIN_BOTH]: {
    [TENANT_ID.AVA]: [
      { ...SHARED_DOCUMENTS[13], triggeredByGroupIds: [1, 3] },
      { ...SHARED_DOCUMENTS[14], triggeredByGroupIds: [1, 3] },
      { ...SHARED_DOCUMENTS[17], triggeredByGroupIds: [1, 3] },
      { ...SHARED_DOCUMENTS[22], triggeredByGroupIds: [11], isOptional: always },
    ],
    [TENANT_ID.EDI]: [
      { ...SHARED_DOCUMENTS[13], triggeredByGroupIds: [1, 2, 3] },
      { ...SHARED_DOCUMENTS[14], triggeredByGroupIds: [1] },
      { ...SHARED_DOCUMENTS[17], triggeredByGroupIds: [1, 2, 3] },
      { ...SHARED_DOCUMENTS[22], triggeredByGroupIds: [11] },
    ],
    [TENANT_ID.SHNG]: [
      { ...SHARED_DOCUMENTS[13], triggeredByGroupIds: [1, 3] },
      { ...SHARED_DOCUMENTS[14], triggeredByGroupIds: [1] },
      { ...SHARED_DOCUMENTS[16], triggeredByGroupIds: [1, 3] },
      { ...SHARED_DOCUMENTS[17], triggeredByGroupIds: [1, 2, 3] },
      { ...SHARED_DOCUMENTS[22], triggeredByGroupIds: [11], isOptional: always },
    ],
    [TENANT_ID.BAG]: [
      { ...SHARED_DOCUMENTS[13], triggeredByGroupIds: [1, 2, 3, 6] },
      { ...SHARED_DOCUMENTS[14], triggeredByGroupIds: [1] },
      { ...SHARED_DOCUMENTS[15], triggeredByGroupIds: [1, 2, 3] },
      { ...SHARED_DOCUMENTS[16], triggeredByGroupIds: [1, 2] },
      { ...SHARED_DOCUMENTS[17], triggeredByGroupIds: [1, 2, 3, 5] },
      { ...SHARED_DOCUMENTS[18], triggeredByGroupIds: [1, 2, 7] },
    ],
  },
  [INSTALLER_ENTRIES.MAIN_ENERGY_GUEST_GAS]: {
    [TENANT_ID.AVA]: [
      { ...SHARED_DOCUMENTS[13], triggeredByGroupIds: [1, 3] },
      { ...SHARED_DOCUMENTS[14], triggeredByGroupIds: [1] },
      { ...SHARED_DOCUMENTS[17], triggeredByGroupIds: [1, 3] },
      { ...SHARED_DOCUMENTS[19], triggeredByGroupIds: [1, 2, 3, 8, 10, 12] },
      { ...SHARED_DOCUMENTS[22], triggeredByGroupIds: [11], isOptional: always },
    ],
    [TENANT_ID.EDI]: [
      { ...SHARED_DOCUMENTS[13], triggeredByGroupIds: [1, 2, 3] },
      { ...SHARED_DOCUMENTS[17], triggeredByGroupIds: [1, 2, 3] },
      { ...SHARED_DOCUMENTS[19], triggeredByGroupIds: [1, 2, 3, 8, 10, 12] },
      { ...SHARED_DOCUMENTS[22], triggeredByGroupIds: [11] },
    ],
    [TENANT_ID.SHNG]: [
      { ...SHARED_DOCUMENTS[13], triggeredByGroupIds: [1, 2, 3] },
      { ...SHARED_DOCUMENTS[17], triggeredByGroupIds: [1, 2, 3] },
      { ...SHARED_DOCUMENTS[19], triggeredByGroupIds: [1, 2, 3, 8, 10, 12] },
      { ...SHARED_DOCUMENTS[22], triggeredByGroupIds: [11], isOptional: always },
    ],
    [TENANT_ID.BAG]: [
      { ...SHARED_DOCUMENTS[13], triggeredByGroupIds: [1, 2, 3, 6] },
      { ...SHARED_DOCUMENTS[17], triggeredByGroupIds: [1, 2, 3, 5] },
      { ...SHARED_DOCUMENTS[18], triggeredByGroupIds: [7] },
      { ...SHARED_DOCUMENTS[19], triggeredByGroupIds: [1, 2, 3, 8, 10, 12] },
    ],
  },
  [INSTALLER_ENTRIES.GUEST_ENERGY_MAIN_GAS]: {
    [TENANT_ID.AVA]: [
      { ...SHARED_DOCUMENTS[13], triggeredByGroupIds: [1, 3] },
      { ...SHARED_DOCUMENTS[14], triggeredByGroupIds: [1, 3] },
      { ...SHARED_DOCUMENTS[17], triggeredByGroupIds: [1, 3] },
      { ...SHARED_DOCUMENTS[19], triggeredByGroupIds: [1, 2, 3, 8, 9, 11] },
    ],
    [TENANT_ID.EDI]: [
      { ...SHARED_DOCUMENTS[13], triggeredByGroupIds: [1, 3] },
      { ...SHARED_DOCUMENTS[14], triggeredByGroupIds: [1] },
      { ...SHARED_DOCUMENTS[17], triggeredByGroupIds: [1, 3] },
      { ...SHARED_DOCUMENTS[19], triggeredByGroupIds: [1, 2, 3, 8, 9, 11] },
    ],
    [TENANT_ID.SHNG]: [
      { ...SHARED_DOCUMENTS[13], triggeredByGroupIds: [1, 3] },
      { ...SHARED_DOCUMENTS[14], triggeredByGroupIds: [1] },
      { ...SHARED_DOCUMENTS[16], triggeredByGroupIds: [1, 3] },
      { ...SHARED_DOCUMENTS[17], triggeredByGroupIds: [1, 3] },
      { ...SHARED_DOCUMENTS[19], triggeredByGroupIds: [1, 2, 3, 8, 9, 11] },
    ],
    [TENANT_ID.BAG]: [
      { ...SHARED_DOCUMENTS[13], triggeredByGroupIds: [1, 2, 3, 6] },
      { ...SHARED_DOCUMENTS[14], triggeredByGroupIds: [1] },
      { ...SHARED_DOCUMENTS[15], triggeredByGroupIds: [1, 2, 3] },
      { ...SHARED_DOCUMENTS[16], triggeredByGroupIds: [1, 2] },
      { ...SHARED_DOCUMENTS[17], triggeredByGroupIds: [1, 2, 3, 5] },
      { ...SHARED_DOCUMENTS[18], triggeredByGroupIds: [1, 2, 7] },
      { ...SHARED_DOCUMENTS[19], triggeredByGroupIds: [1, 2, 3, 8, 9, 11] },
    ],
  },
};

export const DEB_EXPERTS_DOCUMENTS_FIELDS = {
  [INSTALLER_ENTRIES.MAIN_ENERGY_ONLY]: {
    [TENANT_ID.AVA]: [
      {
        ...SHARED_DOCUMENTS[23],
        triggeredByGroupIds: [9],
        whenShouldFieldRender: expertNotOwner,
      },
      { ...SHARED_DOCUMENTS[25], triggeredByGroupIds: [53] },
      { ...SHARED_DOCUMENTS[27], triggeredByGroupIds: [43, 49, 51, 53] },
      { ...SHARED_DOCUMENTS[70], triggeredByGroupIds: [42, 43, 45, 49, 51, 52] },
      { ...SHARED_DOCUMENTS[71], triggeredByGroupIds: [45, 49] },
      { ...SHARED_DOCUMENTS[72], triggeredByGroupIds: [51, 52] },
    ],
    [TENANT_ID.EDI]: [
      { ...SHARED_DOCUMENTS[23], triggeredByGroupIds: [9], whenShouldFieldRender: expertNotOwner },
      { ...SHARED_DOCUMENTS[25], triggeredByGroupIds: [53] },
      { ...SHARED_DOCUMENTS[27], triggeredByGroupIds: [43, 49, 51, 53] },
      { ...SHARED_DOCUMENTS[70], triggeredByGroupIds: [42, 43, 45, 49, 51, 52] },
      { ...SHARED_DOCUMENTS[71], triggeredByGroupIds: [45, 49] },
      { ...SHARED_DOCUMENTS[72], triggeredByGroupIds: [51, 52] },
    ],
    [TENANT_ID.SHNG]: [
      { ...SHARED_DOCUMENTS[25], triggeredByGroupIds: [53] },
      { ...SHARED_DOCUMENTS[27], triggeredByGroupIds: [43, 49, 51, 53] },
      { ...SHARED_DOCUMENTS[70], triggeredByGroupIds: [42, 43, 45, 49, 51, 52] },
      { ...SHARED_DOCUMENTS[71], triggeredByGroupIds: [45, 49] },
      { ...SHARED_DOCUMENTS[72], triggeredByGroupIds: [51, 52] },
    ],
    [TENANT_ID.BAG]: [
      { ...SHARED_DOCUMENTS[25], triggeredByGroupIds: [53] },
      { ...SHARED_DOCUMENTS[27], triggeredByGroupIds: [43, 47, 48, 49, 51, 53] },
      { ...SHARED_DOCUMENTS[70], triggeredByGroupIds: [42, 43, 46, 47, 48, 49, 51, 52] },
      { ...SHARED_DOCUMENTS[71], triggeredByGroupIds: [46, 47, 48] },
      { ...SHARED_DOCUMENTS[72], triggeredByGroupIds: [51, 52] },
    ],
  },
  [INSTALLER_ENTRIES.MAIN_GAS_ONLY]: {
    [TENANT_ID.AVA]: [
      { ...SHARED_DOCUMENTS[23], triggeredByGroupIds: [10], whenShouldFieldRender: expertNotOwner },
      { ...SHARED_DOCUMENTS[24], triggeredByGroupIds: [10] },
      { ...SHARED_DOCUMENTS[26], triggeredByGroupIds: [69] },
      { ...SHARED_DOCUMENTS[28], triggeredByGroupIds: [1, 10, 12] },
      { ...SHARED_DOCUMENTS[29], triggeredByGroupIds: [58, 61, 64, 66, 67, 68, 69] },
      { ...SHARED_DOCUMENTS[73], triggeredByGroupIds: [57, 58, 60, 61, 63, 64] },
      { ...SHARED_DOCUMENTS[74], triggeredByGroupIds: [60, 61] },
      { ...SHARED_DOCUMENTS[75], triggeredByGroupIds: [66, 67, 68] },
      { ...SHARED_DOCUMENTS[76], triggeredByGroupIds: [66, 67, 68] },
      { ...SHARED_DOCUMENTS[77], triggeredByGroupIds: [63, 64] },
    ],
    [TENANT_ID.EDI]: [
      { ...SHARED_DOCUMENTS[23], triggeredByGroupIds: [10], whenShouldFieldRender: expertNotOwner },
      { ...SHARED_DOCUMENTS[24], triggeredByGroupIds: [10] },
      { ...SHARED_DOCUMENTS[26], triggeredByGroupIds: [69] },
      { ...SHARED_DOCUMENTS[28], triggeredByGroupIds: [1, 10, 12] },
      { ...SHARED_DOCUMENTS[29], triggeredByGroupIds: [58, 61, 64, 66, 67, 68, 69] },
      { ...SHARED_DOCUMENTS[73], triggeredByGroupIds: [57, 58, 60, 61, 63, 64] },
      { ...SHARED_DOCUMENTS[74], triggeredByGroupIds: [60, 61] },
      { ...SHARED_DOCUMENTS[75], triggeredByGroupIds: [66, 67, 68] },
      { ...SHARED_DOCUMENTS[76], triggeredByGroupIds: [66, 67, 68] },
      { ...SHARED_DOCUMENTS[77], triggeredByGroupIds: [63, 64] },
    ],
    [TENANT_ID.SHNG]: [
      { ...SHARED_DOCUMENTS[23], triggeredByGroupIds: [10], whenShouldFieldRender: expertNotOwner },
      { ...SHARED_DOCUMENTS[24], triggeredByGroupIds: [10] },
      { ...SHARED_DOCUMENTS[26], triggeredByGroupIds: [69] },
      { ...SHARED_DOCUMENTS[28], triggeredByGroupIds: [1, 10, 12] },
      { ...SHARED_DOCUMENTS[29], triggeredByGroupIds: [58, 61, 64, 66, 67, 68, 69] },
      { ...SHARED_DOCUMENTS[73], triggeredByGroupIds: [57, 58, 60, 61, 63, 64] },
      { ...SHARED_DOCUMENTS[74], triggeredByGroupIds: [60, 61] },
      { ...SHARED_DOCUMENTS[75], triggeredByGroupIds: [66, 67, 68] },
      { ...SHARED_DOCUMENTS[76], triggeredByGroupIds: [66, 67, 68] },
      { ...SHARED_DOCUMENTS[77], triggeredByGroupIds: [63, 64] },
    ],
    [TENANT_ID.BAG]: [
      { ...SHARED_DOCUMENTS[23], triggeredByGroupIds: [10], whenShouldFieldRender: expertNotOwner },
      { ...SHARED_DOCUMENTS[26], triggeredByGroupIds: [69] },
      { ...SHARED_DOCUMENTS[28], triggeredByGroupIds: [1, 2, 3, 10, 12] },
      { ...SHARED_DOCUMENTS[29], triggeredByGroupIds: [58, 61, 64, 66, 67, 68, 69] },
      { ...SHARED_DOCUMENTS[73], triggeredByGroupIds: [57, 58, 60, 61, 63, 64] },
      { ...SHARED_DOCUMENTS[74], triggeredByGroupIds: [60, 61] },
      { ...SHARED_DOCUMENTS[75], triggeredByGroupIds: [66, 67, 68] },
      { ...SHARED_DOCUMENTS[76], triggeredByGroupIds: [66, 67, 68] },
      { ...SHARED_DOCUMENTS[77], triggeredByGroupIds: [63, 64] },
    ],
  },
  [INSTALLER_ENTRIES.GUEST_ONLY]: {
    [TENANT_ID.AVA]: [],
    [TENANT_ID.EDI]: [],
    [TENANT_ID.SHNG]: [],
    [TENANT_ID.BAG]: [],
  },
  [INSTALLER_ENTRIES.MAIN_BOTH]: {
    [TENANT_ID.AVA]: [
      {
        ...SHARED_DOCUMENTS[23],
        triggeredByGroupIds: [9, 10],
        whenShouldFieldRender: expertNotOwner,
      },
      { ...SHARED_DOCUMENTS[24], triggeredByGroupIds: [10] },
      { ...SHARED_DOCUMENTS[25], triggeredByGroupIds: [53] },
      { ...SHARED_DOCUMENTS[26], triggeredByGroupIds: [69] },
      { ...SHARED_DOCUMENTS[27], triggeredByGroupIds: [43, 49, 51, 53] },
      { ...SHARED_DOCUMENTS[28], triggeredByGroupIds: [1, 10, 12] },
      { ...SHARED_DOCUMENTS[29], triggeredByGroupIds: [58, 61, 64, 66, 67, 68, 69] },
      { ...SHARED_DOCUMENTS[70], triggeredByGroupIds: [42, 43, 45, 49, 51, 52] },
      { ...SHARED_DOCUMENTS[71], triggeredByGroupIds: [45, 49] },
      { ...SHARED_DOCUMENTS[72], triggeredByGroupIds: [51, 52] },
      { ...SHARED_DOCUMENTS[73], triggeredByGroupIds: [57, 58, 60, 61, 63, 64] },
      { ...SHARED_DOCUMENTS[74], triggeredByGroupIds: [60, 61] },
      { ...SHARED_DOCUMENTS[75], triggeredByGroupIds: [66, 67, 68] },
      { ...SHARED_DOCUMENTS[76], triggeredByGroupIds: [66, 67, 68] },
      { ...SHARED_DOCUMENTS[77], triggeredByGroupIds: [63, 64] },
    ],
    [TENANT_ID.EDI]: [
      {
        ...SHARED_DOCUMENTS[23],
        triggeredByGroupIds: [9, 10],
        whenShouldFieldRender: expertNotOwner,
      },
      { ...SHARED_DOCUMENTS[24], triggeredByGroupIds: [10] },
      { ...SHARED_DOCUMENTS[25], triggeredByGroupIds: [53] },
      { ...SHARED_DOCUMENTS[26], triggeredByGroupIds: [69] },
      { ...SHARED_DOCUMENTS[27], triggeredByGroupIds: [43, 49, 51, 53] },
      { ...SHARED_DOCUMENTS[28], triggeredByGroupIds: [1, 10, 12] },
      { ...SHARED_DOCUMENTS[29], triggeredByGroupIds: [58, 61, 64, 66, 67, 68, 69] },
      { ...SHARED_DOCUMENTS[70], triggeredByGroupIds: [42, 43, 45, 49, 51, 52] },
      { ...SHARED_DOCUMENTS[71], triggeredByGroupIds: [45, 49] },
      { ...SHARED_DOCUMENTS[72], triggeredByGroupIds: [51, 52] },
      { ...SHARED_DOCUMENTS[73], triggeredByGroupIds: [57, 58, 60, 61, 63, 64] },
      { ...SHARED_DOCUMENTS[74], triggeredByGroupIds: [60, 61] },
      { ...SHARED_DOCUMENTS[75], triggeredByGroupIds: [66, 67, 68] },
      { ...SHARED_DOCUMENTS[76], triggeredByGroupIds: [66, 67, 68] },
      { ...SHARED_DOCUMENTS[77], triggeredByGroupIds: [63, 64] },
    ],
    [TENANT_ID.SHNG]: [
      {
        ...SHARED_DOCUMENTS[23],
        triggeredByGroupIds: [10],
        whenShouldFieldRender: expertNotOwner,
      },
      { ...SHARED_DOCUMENTS[24], triggeredByGroupIds: [10] },
      { ...SHARED_DOCUMENTS[25], triggeredByGroupIds: [53] },
      { ...SHARED_DOCUMENTS[26], triggeredByGroupIds: [69] },
      { ...SHARED_DOCUMENTS[27], triggeredByGroupIds: [43, 49, 51, 53] },
      { ...SHARED_DOCUMENTS[28], triggeredByGroupIds: [1, 10, 12] },
      { ...SHARED_DOCUMENTS[29], triggeredByGroupIds: [58, 61, 64, 66, 67, 68, 69] },
      { ...SHARED_DOCUMENTS[70], triggeredByGroupIds: [42, 43, 45, 49, 51, 52] },
      { ...SHARED_DOCUMENTS[71], triggeredByGroupIds: [45, 49] },
      { ...SHARED_DOCUMENTS[72], triggeredByGroupIds: [51, 52] },
      { ...SHARED_DOCUMENTS[73], triggeredByGroupIds: [57, 58, 60, 61, 63, 64] },
      { ...SHARED_DOCUMENTS[74], triggeredByGroupIds: [60, 61] },
      { ...SHARED_DOCUMENTS[75], triggeredByGroupIds: [66, 67, 68] },
      { ...SHARED_DOCUMENTS[76], triggeredByGroupIds: [66, 67, 68] },
      { ...SHARED_DOCUMENTS[77], triggeredByGroupIds: [63, 64] },
    ],
    [TENANT_ID.BAG]: [
      {
        ...SHARED_DOCUMENTS[23],
        triggeredByGroupIds: [10],
        whenShouldFieldRender: expertNotOwner,
      },
      { ...SHARED_DOCUMENTS[25], triggeredByGroupIds: [53] },
      { ...SHARED_DOCUMENTS[26], triggeredByGroupIds: [69] },
      { ...SHARED_DOCUMENTS[27], triggeredByGroupIds: [43, 47, 48, 49, 51, 53] },
      { ...SHARED_DOCUMENTS[28], triggeredByGroupIds: [1, 2, 3, 10, 12] },
      { ...SHARED_DOCUMENTS[29], triggeredByGroupIds: [58, 61, 64, 66, 67, 68, 69] },
      { ...SHARED_DOCUMENTS[70], triggeredByGroupIds: [42, 43, 46, 47, 48, 49, 51, 52] },
      { ...SHARED_DOCUMENTS[71], triggeredByGroupIds: [46, 47, 48] },
      { ...SHARED_DOCUMENTS[72], triggeredByGroupIds: [51, 52] },
      { ...SHARED_DOCUMENTS[73], triggeredByGroupIds: [57, 58, 60, 61, 63, 64] },
      { ...SHARED_DOCUMENTS[74], triggeredByGroupIds: [60, 61] },
      { ...SHARED_DOCUMENTS[75], triggeredByGroupIds: [66, 67, 68] },
      { ...SHARED_DOCUMENTS[76], triggeredByGroupIds: [66, 67, 68] },
      { ...SHARED_DOCUMENTS[77], triggeredByGroupIds: [63, 64] },
    ],
  },
  [INSTALLER_ENTRIES.MAIN_ENERGY_GUEST_GAS]: {
    [TENANT_ID.AVA]: [
      { ...SHARED_DOCUMENTS[23], triggeredByGroupIds: [9], whenShouldFieldRender: expertNotOwner },
      { ...SHARED_DOCUMENTS[25], triggeredByGroupIds: [53] },
      { ...SHARED_DOCUMENTS[27], triggeredByGroupIds: [43, 49, 51, 53] },
      { ...SHARED_DOCUMENTS[70], triggeredByGroupIds: [42, 43, 45, 49, 51, 52] },
      { ...SHARED_DOCUMENTS[71], triggeredByGroupIds: [45, 49] },
      { ...SHARED_DOCUMENTS[72], triggeredByGroupIds: [51, 52] },
    ],
    [TENANT_ID.EDI]: [
      { ...SHARED_DOCUMENTS[23], triggeredByGroupIds: [9], whenShouldFieldRender: expertNotOwner },
      { ...SHARED_DOCUMENTS[25], triggeredByGroupIds: [53] },
      { ...SHARED_DOCUMENTS[27], triggeredByGroupIds: [43, 49, 51, 53] },
      { ...SHARED_DOCUMENTS[70], triggeredByGroupIds: [42, 43, 45, 49, 51, 52] },
      { ...SHARED_DOCUMENTS[71], triggeredByGroupIds: [45, 49] },
      { ...SHARED_DOCUMENTS[72], triggeredByGroupIds: [51, 52] },
    ],
    [TENANT_ID.SHNG]: [
      { ...SHARED_DOCUMENTS[25], triggeredByGroupIds: [53] },
      { ...SHARED_DOCUMENTS[27], triggeredByGroupIds: [43, 49, 51, 53] },
      { ...SHARED_DOCUMENTS[70], triggeredByGroupIds: [42, 43, 45, 49, 51, 52] },
      { ...SHARED_DOCUMENTS[71], triggeredByGroupIds: [45, 49] },
      { ...SHARED_DOCUMENTS[72], triggeredByGroupIds: [51, 52] },
    ],
    [TENANT_ID.BAG]: [
      { ...SHARED_DOCUMENTS[25], triggeredByGroupIds: [53] },
      { ...SHARED_DOCUMENTS[27], triggeredByGroupIds: [43, 47, 48, 49, 51, 53] },
      { ...SHARED_DOCUMENTS[70], triggeredByGroupIds: [42, 43, 46, 47, 48, 49, 51, 52] },
      { ...SHARED_DOCUMENTS[71], triggeredByGroupIds: [46, 47, 48] },
      { ...SHARED_DOCUMENTS[72], triggeredByGroupIds: [51, 52] },
    ],
  },
  [INSTALLER_ENTRIES.GUEST_ENERGY_MAIN_GAS]: {
    [TENANT_ID.AVA]: [
      {
        ...SHARED_DOCUMENTS[23],
        triggeredByGroupIds: [10],
        whenShouldFieldRender: expertNotOwner,
      },
      { ...SHARED_DOCUMENTS[24], triggeredByGroupIds: [10] },
      { ...SHARED_DOCUMENTS[26], triggeredByGroupIds: [69] },
      { ...SHARED_DOCUMENTS[28], triggeredByGroupIds: [1, 10, 12] },
      { ...SHARED_DOCUMENTS[29], triggeredByGroupIds: [58, 61, 64, 66, 67, 68, 69] },
      { ...SHARED_DOCUMENTS[73], triggeredByGroupIds: [57, 58, 60, 61, 63, 64] },
      { ...SHARED_DOCUMENTS[74], triggeredByGroupIds: [60, 61] },
      { ...SHARED_DOCUMENTS[75], triggeredByGroupIds: [66, 67, 68] },
      { ...SHARED_DOCUMENTS[76], triggeredByGroupIds: [66, 67, 68] },
      { ...SHARED_DOCUMENTS[77], triggeredByGroupIds: [63, 64] },
    ],
    [TENANT_ID.EDI]: [
      {
        ...SHARED_DOCUMENTS[23],
        triggeredByGroupIds: [10],
        whenShouldFieldRender: expertNotOwner,
      },
      { ...SHARED_DOCUMENTS[24], triggeredByGroupIds: [10] },
      { ...SHARED_DOCUMENTS[26], triggeredByGroupIds: [69] },
      { ...SHARED_DOCUMENTS[28], triggeredByGroupIds: [1, 10, 12] },
      { ...SHARED_DOCUMENTS[29], triggeredByGroupIds: [58, 61, 64, 66, 67, 68, 69] },
      { ...SHARED_DOCUMENTS[73], triggeredByGroupIds: [57, 58, 60, 61, 63, 64] },
      { ...SHARED_DOCUMENTS[74], triggeredByGroupIds: [60, 61] },
      { ...SHARED_DOCUMENTS[75], triggeredByGroupIds: [66, 67, 68] },
      { ...SHARED_DOCUMENTS[76], triggeredByGroupIds: [66, 67, 68] },
      { ...SHARED_DOCUMENTS[77], triggeredByGroupIds: [63, 64] },
    ],
    [TENANT_ID.SHNG]: [
      {
        ...SHARED_DOCUMENTS[23],
        triggeredByGroupIds: [10],
        whenShouldFieldRender: expertNotOwner,
      },
      { ...SHARED_DOCUMENTS[24], triggeredByGroupIds: [10] },
      { ...SHARED_DOCUMENTS[26], triggeredByGroupIds: [69] },
      { ...SHARED_DOCUMENTS[28], triggeredByGroupIds: [1, 10, 12] },
      { ...SHARED_DOCUMENTS[29], triggeredByGroupIds: [58, 61, 64, 66, 67, 68, 69] },
      { ...SHARED_DOCUMENTS[73], triggeredByGroupIds: [57, 58, 60, 61, 63, 64] },
      { ...SHARED_DOCUMENTS[74], triggeredByGroupIds: [60, 61] },
      { ...SHARED_DOCUMENTS[75], triggeredByGroupIds: [66, 67, 68] },
      { ...SHARED_DOCUMENTS[76], triggeredByGroupIds: [66, 67, 68] },
      { ...SHARED_DOCUMENTS[77], triggeredByGroupIds: [63, 64] },
    ],
    [TENANT_ID.BAG]: [
      {
        ...SHARED_DOCUMENTS[23],
        triggeredByGroupIds: [10],
        whenShouldFieldRender: expertNotOwner,
      },
      { ...SHARED_DOCUMENTS[26], triggeredByGroupIds: [69] },
      { ...SHARED_DOCUMENTS[28], triggeredByGroupIds: [1, 2, 3, 10, 12] },
      { ...SHARED_DOCUMENTS[29], triggeredByGroupIds: [58, 61, 64, 66, 67, 68, 69] },
      { ...SHARED_DOCUMENTS[73], triggeredByGroupIds: [57, 58, 60, 61, 63, 64] },
      { ...SHARED_DOCUMENTS[74], triggeredByGroupIds: [60, 61] },
      { ...SHARED_DOCUMENTS[75], triggeredByGroupIds: [66, 67, 68] },
      { ...SHARED_DOCUMENTS[76], triggeredByGroupIds: [66, 67, 68] },
      { ...SHARED_DOCUMENTS[77], triggeredByGroupIds: [63, 64] },
    ],
  },
};
