import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { InputRow } from 'components/FormHelper/InputRow';
import { FormSection } from 'components/FormHelper/FormSection';
import { InputField } from 'components/FormHelper/InputField';
import { allowOnlyNumber, checkKeyDown, FFormDebug } from 'utils/FormUtils';
import { useNESteps } from 'context/NEStepsContext';
import { NE_FIRST_SECTION_INPUTS, checkErrors } from './NEFormUtility';
import { isRegisteringInstallerOnlyHilfsbetrieb } from 'utils/InstallerUtils';

import { FormPageWrapper } from 'components/FormHelper/FormPageWrapper';

import { NEExpertTable } from 'components/NEComponents/NEExpertsTable/NEExpertsTable';
import { ActionButton } from 'components/ActionButton/ActionButton';
import PlusIcon from 'components/Icons/PlusIcon';
import { NENewExpertPopup } from 'components/NEComponents/NENewExpertPopup/NENewExpertPopup';
import { NEFormIV } from './NEFormIV';
import { NEAgreementSection } from 'components/NEForm/NEAgreementSection';
import { YNEStepFormPageSchema } from 'components/NEValidation/YNEStepFormPageSchema';
import { NE_ERROR_MESSAGE } from 'components/NEForm/Utils/NEError';

import { CURRENT_TEST_SUITE } from 'components/NEForm/NETestdata/NETestSetup';
import { NE_ACTIVE_STEP } from 'global/constants';
import { evaluateCondition } from 'global/fieldValidationFunction';

const NEFormPage = ({ energyAgreementLink, gasAgreementLink, setButtonErrorMessage }) => {
  const { shouldFillNETestData, setSubmittedValuesWithStep, getSubmittedValuesWithStep } =
    useNESteps();

  const branchSelectionValues = getSubmittedValuesWithStep(NE_ACTIVE_STEP.BRANCH_SELECTION);

  const [newExpertPopupOpen, setNewExpertPopupOpen] = useState(false);
  const [errors, setErrors] = useState([]);
  const initialValues = shouldFillNETestData
    ? CURRENT_TEST_SUITE.step2
    : NEFormIV(
        getSubmittedValuesWithStep(NE_ACTIVE_STEP.EMAIL_INPUT),
        getSubmittedValuesWithStep(NE_ACTIVE_STEP.BRANCH_SELECTION),
        getSubmittedValuesWithStep(NE_ACTIVE_STEP.FORM)
      );
  const formik = useFormik({
    validationSchema: YNEStepFormPageSchema(
      getSubmittedValuesWithStep(NE_ACTIVE_STEP.BRANCH_SELECTION)
    ),
    initialValues: initialValues,
    enableReinitialize: true,
    validateOnMount: true,
    validateOnChange: true,
  });

  const { values, errors: formErrors } = formik;

  useEffect(() => {
    setSubmittedValuesWithStep(NE_ACTIVE_STEP.FORM, values);
  }, [values]);

  useEffect(() => {
    const errors = checkErrors(
      values,
      getSubmittedValuesWithStep(NE_ACTIVE_STEP.BRANCH_SELECTION),
      formErrors
    );
    setErrors(errors);
    if (errors.length > 0) {
      setButtonErrorMessage(NE_ERROR_MESSAGE);
    } else {
      setButtonErrorMessage('');
    }
  }, [values, formErrors]);

  return (
    <>
      <form
        onKeyDown={checkKeyDown}
        onSubmit={(e) => {
          e.preventDefault();
        }}>
        <FormPageWrapper>
          {NE_FIRST_SECTION_INPUTS.map((section) => {
            const evaluateConditionOptions = {
              isOnlyHilfsbetrieb: isRegisteringInstallerOnlyHilfsbetrieb(branchSelectionValues),
              branchSelectionValues,
            };
            if (evaluateCondition(section.whenShouldSectionRender, evaluateConditionOptions)) {
              return (
                <FormSection
                  key={section.id}
                  title={section.title}
                  subtitle={section.subtitle}
                  infoText={section.infoText}
                  tooltip={section.tooltip}
                  navigationTooltip={section.navigationTooltip}>
                  <InputRow>
                    {section.fields?.map((field) => {
                      return evaluateCondition(
                        field.whenShouldFieldRender,
                        evaluateConditionOptions
                      ) ? (
                        <InputField
                          infoText={field.infoText}
                          key={field.name}
                          name={field.name}
                          fullWidth
                          label={`${field.fieldName}${
                            !field.optional ||
                            evaluateCondition(field.optional, evaluateConditionOptions)
                              ? ''
                              : '*'
                          }`}
                          variant="standard"
                          disabled={field.readOnly}
                          value={values[field.name]}
                          onChange={
                            field.name == 'avaGuestPostal'
                              ? (e) => {
                                  let fixedEvent = allowOnlyNumber(e);
                                  formik.handleChange(fixedEvent);
                                }
                              : formik.handleChange
                          }
                          error={Boolean(formik.errors[field.name])}
                          helperText={formik.errors[field.name] || ''}
                        />
                      ) : null;
                    })}
                  </InputRow>
                </FormSection>
              );
            } else return null;
          })}
          <FormSection title="Fachkräfte hinzufügen">
            <NEExpertTable
              formik={formik}
              errors={errors}
              branchSelectionValues={getSubmittedValuesWithStep(NE_ACTIVE_STEP.BRANCH_SELECTION)}
            />
            <ActionButton
              inverted
              icon={<PlusIcon />}
              title={<div className="text-nowrap"> Fachkraft hinzufügen</div>}
              onClick={() => {
                setNewExpertPopupOpen(true);
              }}></ActionButton>
            <NENewExpertPopup
              branchSelectionValues={getSubmittedValuesWithStep(NE_ACTIVE_STEP.BRANCH_SELECTION)}
              popupOpen={newExpertPopupOpen}
              setPopupOpen={setNewExpertPopupOpen}
              mainFormik={formik}></NENewExpertPopup>
          </FormSection>
          <FormSection>
            <NEAgreementSection
              formik={formik}
              branchSelectionValues={getSubmittedValuesWithStep(NE_ACTIVE_STEP.BRANCH_SELECTION)}
              energyAgreementLink={energyAgreementLink}
              gasAgreementLink={gasAgreementLink}
            />
          </FormSection>
        </FormPageWrapper>
        <FFormDebug values={getSubmittedValuesWithStep(NE_ACTIVE_STEP.FORM)} />
      </form>
    </>
  );
};

NEFormPage.propTypes = {
  energyAgreementLink: PropTypes.string,
  gasAgreementLink: PropTypes.string,
  setButtonErrorMessage: PropTypes.func.isRequired,
};

export { NEFormPage };
