import {
  DEB_CURRENT_TEST_SUITE,
  shouldFillDebTestData,
} from 'components/DEBForm/Utils/DebTestSetup';
import { EIV_ENDPOINT } from '../APIUtils';
import { getAxiosInstance } from '../InitAxios';

export const getInstallersContractsByInstallerId = async (id, setShowError) => {
  if (shouldFillDebTestData) {
    return DEB_CURRENT_TEST_SUITE.installerData.contracts;
  }
  const axiosInstance = getAxiosInstance();
  return axiosInstance
    .get(EIV_ENDPOINT.INSTALLER_CONTRACTS_BY_ID(id))
    .then((response) => {
      return response.data;
    })
    .catch(() => {
      setShowError(true);
    });
};
