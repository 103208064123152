import React, { useMemo } from 'react';
import './LabelRadiobutton.sass';
import PropTypes from 'prop-types';
import { CustomRadiobutton } from 'components/CustomRadiobutton/CustomRadiobutton';
import { InfoTooltip } from 'components/InfoTooltip/InfoTooltip';

const LabelRadiobutton = ({
  label,
  id,
  value,
  onChange,
  disabled,
  icon,
  isEdited,
  name,
  tooltipText,
  error,
  checked,
}) => {
  const getClassnameForError = useMemo(() => {
    if (error) return 'error';
    return '';
  }, [error]);

  return (
    <div className="label-radiobutton">
      <CustomRadiobutton
        id={id}
        value={value}
        onChange={onChange}
        disabled={disabled}
        name={name}
        isEdited={isEdited}
        tooltipText={tooltipText}
        error={error}
        checked={checked}
      />
      {icon && <img src={icon} alt="label-radiobutton-icon" />}
      {label && (
        <label id={id} className={getClassnameForError}>
          {label}
        </label>
      )}
      {tooltipText && <InfoTooltip title={tooltipText}></InfoTooltip>}
    </div>
  );
};

LabelRadiobutton.propTypes = {
  value: PropTypes.oneOf(
    PropTypes.string.isRequired,
    PropTypes.number.isRequired,
    PropTypes.bool.isRequired
  ),
  label: PropTypes.string,
  id: PropTypes.string.isRequired,
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  icon: PropTypes.string,
  isEdited: PropTypes.bool,
  name: PropTypes.string.isRequired,
  tooltipText: PropTypes.string,
  error: PropTypes.bool,
};

export { LabelRadiobutton };
