import { getTenantId } from 'utils/tenantUtil';
import { getBranchSelectionData } from './BranchSelectionData';
import { NE_EI } from './EmailInput';
import { getFormDataConfigs } from './FormDataConfigs';

export const TEST_SUITES = (tenantId) => ({
  MainEnergyNonH: {
    step0: NE_EI,
    step1: getBranchSelectionData('MainEnergyNonH', ''),
    step2: getFormDataConfigs(tenantId, getBranchSelectionData('MainEnergyNonH', '')),
    step3: {},
    id: 1,
  },
  MainEnergyH: {
    step0: NE_EI,
    step1: getBranchSelectionData('MainEnergyH', ''),
    step2: getFormDataConfigs(tenantId, getBranchSelectionData('MainEnergyH', '')),
    step3: {},
    id: 2,
  },
  MainGasNonH: {
    step0: NE_EI,
    step1: getBranchSelectionData('', 'MainGasNonH'),
    step2: getFormDataConfigs(tenantId, getBranchSelectionData('', 'MainGasNonH')),
    step3: {},
    id: 3,
  },
  MainGasH: {
    step0: NE_EI,
    step1: getBranchSelectionData('', 'MainGasH'),
    step2: getFormDataConfigs(tenantId, getBranchSelectionData('', 'MainGasH')),
    step3: {},
    id: 4,
  },
  MainEnergyNonHMainGasNonH: {
    step0: NE_EI,
    step1: getBranchSelectionData('MainEnergyNonH', 'MainGasNonH'),
    step2: getFormDataConfigs(tenantId, getBranchSelectionData('MainEnergyNonH', 'MainGasNonH')),
    step3: {},
    id: 5,
  },
  MainEnergyHMainGasNonH: {
    step0: NE_EI,
    step1: getBranchSelectionData('MainEnergyH', 'MainGasNonH'),
    step2: getFormDataConfigs(tenantId, getBranchSelectionData('MainEnergyH', 'MainGasNonH')),
    step3: {},
    id: 6,
  },
  MainEnergyNonHMainGasH: {
    step0: NE_EI,
    step1: getBranchSelectionData('MainEnergyNonH', 'MainGasH'),
    step2: getFormDataConfigs(tenantId, getBranchSelectionData('MainEnergyNonH', 'MainGasH')),
    step3: {},
    id: 7,
  },
  MainEnergyHMainGasH: {
    step0: NE_EI,
    step1: getBranchSelectionData('MainEnergyH', 'MainGasH'),
    step2: getFormDataConfigs(tenantId, getBranchSelectionData('MainEnergyH', 'MainGasH')),
    step3: {},
    id: 8,
  },
  MainEnergyNonHGuestGasNonH: {
    step0: NE_EI,
    step1: getBranchSelectionData('MainEnergyNonH', 'GuestGasNonH'),
    step2: getFormDataConfigs(tenantId, getBranchSelectionData('MainEnergyNonH', 'GuestGasNonH')),
    step3: {},
    id: 9,
  },
  MainEnergyHGuestGasNonH: {
    step0: NE_EI,
    step1: getBranchSelectionData('MainEnergyH', 'GuestGasNonH'),
    step2: getFormDataConfigs(tenantId, getBranchSelectionData('MainEnergyH', 'GuestGasNonH')),
    step3: {},
    id: 10,
  },
  MainEnergyNonHGuestGasH: {
    step0: NE_EI,
    step1: getBranchSelectionData('MainEnergyNonH', 'GuestGasH'),
    step2: getFormDataConfigs(tenantId, getBranchSelectionData('MainEnergyNonH', 'GuestGasH')),
    step3: {},
    id: 11,
  },
  MainEnergyHGuestGasH: {
    step0: NE_EI,
    step1: getBranchSelectionData('MainEnergyH', 'GuestGasH'),
    step2: getFormDataConfigs(tenantId, getBranchSelectionData('MainEnergyH', 'GuestGasH')),
    step3: {},
    id: 12,
  },
  GuestEnergyNonHMainGasNonH: {
    step0: NE_EI,
    step1: getBranchSelectionData('GuestEnergyNonH', 'MainGasNonH'),
    step2: getFormDataConfigs(tenantId, getBranchSelectionData('GuestEnergyNonH', 'MainGasNonH')),
    step3: {},
    id: 13,
  },
  GuestEnergyNonHMainGasH: {
    step0: NE_EI,
    step1: getBranchSelectionData('GuestEnergyNonH', 'MainGasH'),
    step2: getFormDataConfigs(tenantId, getBranchSelectionData('GuestEnergyNonH', 'MainGasH')),
    step3: {},
    id: 14,
  },
  GuestEnergyHMainGasNonH: {
    step0: NE_EI,
    step1: getBranchSelectionData('GuestEnergyH', 'MainGasNonH'),
    step2: getFormDataConfigs(tenantId, getBranchSelectionData('GuestEnergyH', 'MainGasNonH')),
    step3: {},
    id: 15,
  },
  GuestEnergyHMainGasH: {
    step0: NE_EI,
    step1: getBranchSelectionData('GuestEnergyH', 'MainGasH'),
    step2: getFormDataConfigs(tenantId, getBranchSelectionData('GuestEnergyH', 'MainGasH')),
    step3: {},
    id: 16,
  },
  GuestEnergyNonH: {
    step0: NE_EI,
    step1: getBranchSelectionData('GuestEnergyNonH', ''),
    step2: getFormDataConfigs(tenantId, getBranchSelectionData('GuestEnergyNonH', '')),
    step3: {},
    id: 17,
  },
  GuestGasNonH: {
    step0: NE_EI,
    step1: getBranchSelectionData('', 'GuestGasNonH'),
    step2: getFormDataConfigs(tenantId, getBranchSelectionData('', 'GuestGasNonH')),
    step3: {},
    id: 18,
  },
  GuestEnergyH: {
    step0: NE_EI,
    step1: getBranchSelectionData('GuestEnergyH', ''),
    step2: getFormDataConfigs(tenantId, getBranchSelectionData('GuestEnergyH', '')),
    step3: {},
    id: 19,
  },
  GuestGasH: {
    step0: NE_EI,
    step1: getBranchSelectionData('', 'GuestGasH'),
    step2: getFormDataConfigs(tenantId, getBranchSelectionData('', 'GuestGasH')),
    step3: {},
    id: 20,
  },
  GuestEnergyNonHGuestGasNonH: {
    step0: NE_EI,
    step1: getBranchSelectionData('GuestEnergyNonH', 'GuestGasNonH'),
    step2: getFormDataConfigs(tenantId, getBranchSelectionData('GuestEnergyNonH', 'GuestGasNonH')),
    step3: {},
    id: 21,
  },
  GuestEnergyHGuestGasNonH: {
    step0: NE_EI,
    step1: getBranchSelectionData('GuestEnergyH', 'GuestGasNonH'),
    step2: getFormDataConfigs(tenantId, getBranchSelectionData('GuestEnergyH', 'GuestGasNonH')),
    step3: {},
    id: 22,
  },
  GuestEnergyNonHGuestGasH: {
    step0: NE_EI,
    step1: getBranchSelectionData('GuestEnergyNonH', 'GuestGasH'),
    step2: getFormDataConfigs(tenantId, getBranchSelectionData('GuestEnergyNonH', 'GuestGasH')),
    step3: {},
    id: 23,
  },
  GuestEnergyHGuestGasH: {
    step0: NE_EI,
    step1: getBranchSelectionData('GuestEnergyH', 'GuestGasH'),
    step2: getFormDataConfigs(tenantId, getBranchSelectionData('GuestEnergyH', 'GuestGasH')),
    step3: {},
    id: 24,
  },
});

const CURRENT_TEST_SUITE = TEST_SUITES(getTenantId())[
  localStorage.getItem('NE_SELECTED_TEST_SUITE') || 'MainEnergyNonH'
];

console.log(
  'Current Testsuite:',
  getTenantId(),
  localStorage.getItem('NE_SELECTED_TEST_SUITE') || 'MainEnergyNonH',
  CURRENT_TEST_SUITE
);

export { CURRENT_TEST_SUITE };
