import { avaconGuestBothFormData, avaconGuestBothInstallerData } from '../Testing/Avacon/GuestBoth';
import {
  bagEnergyMainFormData,
  bagEnergyMainInstallerData,
  bagGasMainFormData,
  bagGasMainInstallerData,
} from '../Testing/Bag/EnergyMain';

import {
  energyMainHilfsbetriebGasGuestHauptbetriebFormData,
  energyMainHilfsbetriebGasGuestHauptbetriebInstallerData,
} from '../Testing/Bag/EnergyMainHilfsbetriebGasGuestHauptbetrieb';

import {
  shngEnergyGuestGasMainFormData,
  shngEnergyGuestGasMainInstallerData,
} from '../Testing/Shng/EnergyGuestGasMain';
import { getTenantId } from 'utils/tenantUtil';
import { createInstallerData } from 'components/DEBForm/Utils/DebTestAccountSetup';
const TEST_SUITES = {
  avaconGuestBoth: {
    formData: avaconGuestBothFormData,
    installerData: avaconGuestBothInstallerData,
  },
  bagGasMain: {
    formData: bagGasMainFormData,
    installerData: bagGasMainInstallerData,
  },
  bagEnergyMain: {
    formData: bagEnergyMainFormData,
    installerData: bagEnergyMainInstallerData,
  },
  bagEnergyMainHilfsbertriebGasGuestHauptbetrieb: {
    formData: energyMainHilfsbetriebGasGuestHauptbetriebFormData,
    installerData: energyMainHilfsbetriebGasGuestHauptbetriebInstallerData,
  },
  shngEnergyGuestGasMain: {
    formData: shngEnergyGuestGasMainFormData,
    installerData: shngEnergyGuestGasMainInstallerData,
  },
  //new one
  GuestEnergyNonH: {
    installerData: createInstallerData(getTenantId(), 'GuestEnergyNonH', ''),
  },
};
const shouldFillDebTestData = localStorage.getItem('FILL_DEB_FORM') === 'true';
const DEB_CURRENT_TEST_SUITE =
  TEST_SUITES[localStorage.getItem('DEB_SELECTED_TEST_SUITE') || 'GuestEnergyNonH'];
const DEB_SELECTED_TEST_SUITE = localStorage.getItem('DEB_SELECTED_TEST_SUITE');
export { DEB_CURRENT_TEST_SUITE, shouldFillDebTestData, DEB_SELECTED_TEST_SUITE };
