import React, { useEffect } from 'react';
import { MapTo } from '@adobe/aem-react-editable-components';
import './ServiceProvider.scss';
import { flatCards } from '../../components/card-utils';
import ContactPersonItem from '../../components/ContactPersonItem/ContactPersonItem';
import NetworkConnectionSubText from '../../components/NetworkConnectionSubText/NetworkConnectionSubText';
import { PageTitleSection } from '../../../PageTitleSection/PageTitleSection';
import { SubTitle } from '../../../SubTitle/SubTitle';

const CONTENT = {
  title: 'Ansprechpartner Dienstleister',
  description:
    'Hier finden Sie eine Übersicht unserer Dienstleister für Zählermontagen, Entstörung, Recherchen und Kontrollen',
  power: {
    title: 'Strom',
    cards: [
      {
        title: 'Burgwedel, Gehrden, Sarstedt',
        data: [
          {
            name: 'ELTEL Networks GmbH',
            phoneNumber: '+492361497920',
            phoneNumberDisplay: '0 2361 49792-0',
            email: 'info@u-serv.de',
          },
        ],
      },
      {
        title: 'Gardelegen, Genthin, Oschersleben, Schöningen',
        data: [
          {
            name: 'Achilles Elektrotechnik',
            phoneNumber: '+4939426238',
            phoneNumberDisplay: '039426-238',
            email: 'stromzaehler@achilles-elektrotechnik.de',
          },
        ],
      },
      {
        title: 'Lüneburg, Salzwedel',
        data: [
          {
            name: 'Deutsche Zählergesellschaft',
            phoneNumber: '+498001015905',
            phoneNumberDisplay: '0800 10 15905',
            email: 'info@dzg-service.de',
          },
        ],
      },
      {
        title: 'Salzgitter',
        data: [
          {
            name: 'WEVG Salzgitter GmbH & Co.KG.',
            phoneNumber: '+495341408478',
            phoneNumberDisplay: '05341 4-08478',
            email: 'zaehler-sz@avacon.de',
          },
        ],
      },
      {
        title: 'Nienburg, Syke',
        data: [
          {
            name: 'LF Service GmbH & Co. KG',
            phoneNumbers: ['+498004447898', '+494419704198', '+494419704196'],
            phoneNumberDisplay: ['0800 44 47898', '0441 97 04198', '0441 97 04196'],
            emails: [
              'service@lfservice.de',
              'susanne.hooper@lfservice.de',
              'd.guenther@lfservice.de',
            ],
          },
        ],
      },
    ],
  },
  gas: {
    title: 'Gas',
    cards: [
      {
        title: 'Burgwedel, Gehrden, Lüneburg, Nienburg, Salzwedel, Sarstedt, Syke',
        data: [
          {
            name: 'Energie Mess- und Servicedienst GmbH',
            phoneNumber: '+498007777645',
            phoneNumberDisplay: '0800 77 77645',
            email: 'klaerungsfaelle-ekn@enermess.de',
          },
        ],
      },
      {
        title: 'Gardelegen, Genthin, Oschersleben, Schöningen',
        data: [
          {
            name: 'Firma Juska',
            phoneNumber: '+491736010581',
            phoneNumberDisplay: '0173 60 10581',
            email: 'F.Juska@juska-heizung-sanitaer.com',
          },
        ],
      },
      {
        title: 'Salzgitter',
        data: [
          {
            name: 'WEVG Salzgitter GmbH & Co.KG.',
            phoneNumber: '+495341408478',
            phoneNumberDisplay: '05341 4-08478',
            email: 'zaehler-sz@avacon.de',
          },
        ],
      },
    ],
  },
  firstSubText:
    'Sie sind sich nicht sicher, welchem unserer Betriebsstandorte Ihr Bauvorhaben zugeordnet ist?',
  secondSubText: 'Dann nutzen Sie gerne unsere ',
  secondSubTextActionText: 'Standortsuche',
  secondSubTextAfterAction: '.',
  secondSubTextAction:
    'https://avacon-standortsuche.regiocom.net/lokaextern/App/Suche/Standortsuche.aspx',
};

const ServiceProviderComponent = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { power, gas, ...data } = CONTENT;

  const powerContactSectionCards = flatCards(power.cards);
  const gasContactSectionCards = flatCards(gas.cards);

  return (
    <div className=" expert-page serviceProviderComponent">
      <div className="expert-page-content-stage-text">
        <PageTitleSection title={data.title} subText={data.description} notFramed />

        <SubTitle text={power.title} notFramed />
        <div className="contactServiceProviderContactSectionContainer">
          {powerContactSectionCards.map((details, index) => (
            <div key={index} className="contactServiceProviderContactSectionItem">
              <ContactPersonItem
                title={details?.title}
                name={details.name}
                phoneNumber={details.phoneNumber}
                phoneNumberDisplay={details.phoneNumberDisplay}
                email={details.email}
                emails={details.emails}
                phoneNumbers={details.phoneNumbers}
              />
            </div>
          ))}
        </div>

        <SubTitle text={gas.title} notFramed />
        <div className="contactServiceProviderContactSectionContainer">
          {gasContactSectionCards.map((details, index) => (
            <div key={index} className="contactServiceProviderContactSectionItem">
              <ContactPersonItem
                title={details?.title}
                name={details.name}
                phoneNumber={details.phoneNumber}
                phoneNumberDisplay={details.phoneNumberDisplay}
                email={details.email}
              />
            </div>
          ))}
        </div>

        <div className="bottom-text-wrapper">
          <div>{data.firstSubText}</div>
          <NetworkConnectionSubText
            text={data.secondSubText}
            textAction={data?.secondSubTextActionText}
            textAfterEmail={data?.secondSubTextAfterAction}
            textActionLink={data?.secondSubTextAction}
          />
        </div>
      </div>
    </div>
  );
};

MapTo(['e-fix/components/content/avaconserviceprovider'])(ServiceProviderComponent);
