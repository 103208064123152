import { InputField } from 'components/FormHelper/InputField';
import { LabelCheckbox } from 'components/LabelCheckbox/LabelCheckbox';
import { useNESteps } from 'context/NEStepsContext';
import { NE_ACTIVE_STEP, testSuitesKeys } from 'global/constants';
import React, { useMemo } from 'react';
import './FormTestingBanner.sass';
import { isProduction } from 'utils/StageUtils';
function NEFormTestingBanner() {
  const { shouldFillNETestData, activeStep } = useNESteps();
  const toggleTestSetup = () => {
    const shouldFillNETestData = localStorage.getItem('FILL_NE_FORM') === 'true';
    localStorage.setItem('FILL_NE_FORM', !shouldFillNETestData);
    window.location.reload();
  };

  const changeTestingAccount = (e) => {
    localStorage.setItem('NE_SELECTED_TEST_SUITE', e.target.value);
    window.location.reload();
  };

  const isTestingSuiteActive = localStorage.getItem('FILL_NE_FORM') === 'true';

  const NETestingAccounts = () => {
    return testSuitesKeys.map((key) => {
      return {
        label: key,
        value: key,
      };
    });
  };

  const renderedTextForAlert = useMemo(() => {
    return activeStep === NE_ACTIVE_STEP.FORM
      ? 'Änderungen können durch den Tester vorgenommen werden.'
      : 'Es dürfen keine Änderungen vorgenommen werden.';
  }, [activeStep]);
  if (isProduction) {
    return null;
  } else
    return (
      <div>
        {activeStep === NE_ACTIVE_STEP.EMAIL_INPUT ? (
          <>
            <div className="test-alert">
              <LabelCheckbox
                name={'testSetup'}
                value={isTestingSuiteActive}
                onChange={toggleTestSetup}
                label={'Testdaten laden?'}></LabelCheckbox>
              {isTestingSuiteActive ? (
                <InputField
                  fullWidth
                  select
                  items={NETestingAccounts()}
                  label={'Testfall wählen'}
                  onChange={changeTestingAccount}
                  name={`selectedTestAccount`}
                  id={`selectedTestAccount`}
                  value={localStorage.getItem('NE_SELECTED_TEST_SUITE') || 'MainEnergyNonH'}
                  oldValue={''}></InputField>
              ) : null}
            </div>
          </>
        ) : shouldFillNETestData ? (
          <div className="test-alert">
            <div>
              <span className="test-alert-bold">Geladene Testdaten: </span>
              {localStorage.getItem('NE_SELECTED_TEST_SUITE') || 'MainEnergyNonH'}
            </div>
            <div>
              <span className="test-alert-bold">Bitte beachten: </span> {renderedTextForAlert}
            </div>
          </div>
        ) : null}
      </div>
    );
}

export default NEFormTestingBanner;
