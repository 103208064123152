import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { CustomModal } from 'components/CustomModal';
import { InputField } from '../../FormHelper/InputField';
import { ActionButton } from 'components/ActionButton/ActionButton';
import './Popup.sass';
import { getTenantId } from 'utils/tenantUtil';
import { BRANCH_TYPE, EIV_STATUS, EXPERT_EDITING_STATUS, TENANT_ID } from 'global/constants';
import { LabelRadiobutton } from 'components/LabelRadiobutton/LabelRadiobutton';
import { evaluateCondition } from 'global/fieldValidationFunction';
import { FIRST_QUALIFICATIONS, SECOND_QUALIFICATIONS } from 'global/qualifications';
import { InfoBox } from 'components/InfoBox/InfoBox';

export function ExpertPopupEnergy({
  formik,
  selectedRow,
  popupOpen,
  setPopupOpen,
  edit = false,
  isGuest,
  oldData,
}) {
  useEffect(() => {
    if (selectedRow !== null && formik.values.experts[`${selectedRow}`]) {
      let status = '';
      if (popupOpen) {
        status = edit === true ? EXPERT_EDITING_STATUS.EDIT : EXPERT_EDITING_STATUS.NEW;
        if (edit) {
          formik.setFieldValue(`experts.${selectedRow}.expertEnergyValidityDate`, '');
        }
      }
      if (formik.values.experts[selectedRow]) {
        formik.setFieldValue(`experts.${selectedRow}.editingStatusEnergy`, status);
      }
    }
  }, [selectedRow, edit, popupOpen]);

  const onCancel = () => {
    if (formik.values.experts[`${selectedRow}`].statusEnergy === EIV_STATUS.NEW && !edit) {
      formik.setFieldValue(`experts`, formik.values.experts.slice(0, -1));
    } else if (edit) {
      if (oldData[`${selectedRow}`]) {
        formik.setFieldValue(`experts[${selectedRow}]`, {
          ...formik.values.experts[`${selectedRow}`],
          lastName: oldData[`${selectedRow}`].lastName,
          firstName: oldData[`${selectedRow}`].firstName,
          birthdate: oldData[`${selectedRow}`].birthdate,
          expertEnergyValidityDate: oldData[`${selectedRow}`].expertEnergyValidityDate,
        });
      }
    }
    setPopupOpen(false);
  };
  useEffect(() => {
    if (formik.values.experts && formik.values.experts[`${selectedRow}`]) {
      const secondQualficationsItems = Object.keys(SECOND_QUALIFICATIONS)
        .filter((qualificationsKey) =>
          evaluateCondition(SECOND_QUALIFICATIONS[qualificationsKey].whenShouldFieldRender, {
            branch: BRANCH_TYPE.ENERGY,
            firstQualificationEnergy:
              formik.values.experts[`${selectedRow}`].firstQualificationEnergy,
          })
        )
        .map((qualificationsKey) => SECOND_QUALIFICATIONS[qualificationsKey]);
      if (secondQualficationsItems.length == 1) {
        formik.setFieldValue(
          `experts[${selectedRow}].secondQualificationEnergy`,
          secondQualficationsItems[0].value
        );
      }
    }
  }, [formik.values]);

  useEffect(() => {
    if (formik.values.experts && formik.values.experts[`${selectedRow}`]) {
      formik.setFieldValue(`experts[${selectedRow}].secondQualificationEnergy`, '');
    }
  }, [formik.values.experts[selectedRow]?.firstQualificationEnergy]);

  const onSubmit = () => {
    if (!formik.errors.experts) {
      if (
        formik.values.experts[`${selectedRow}`].lastName &&
        formik.values.experts[`${selectedRow}`].firstName &&
        !edit
      ) {
        formik.values.experts.forEach((expert) => {
          if (
            formik.values.experts[`${selectedRow}`].lastName.trim() === expert?.lastName.trim() &&
            formik.values.experts[`${selectedRow}`].firstName.trim() === expert?.firstName.trim() &&
            formik.values.experts[`${selectedRow}`].isExpertOwner === expert?.isExpertOwner &&
            expert.id != formik.values.experts[`${selectedRow}`].id
          ) {
            const copyExpert = { ...expert };
            const copyThis = { ...formik.values.experts[`${selectedRow}`] };
            const newObject = {
              ...copyExpert,
              ...copyThis,
              statusGas: copyExpert.statusGas,
              statusEnergy: copyThis.statusEnergy,
              expertGasValidityDate: copyExpert.expertGasValidityDate,
              expertEnergyValidityDate: copyThis.expertEnergyValidityDate,
              isMainGas: copyExpert.isMainGas,
              firstQualificationGas: copyExpert.firstQualificationGas,
              secondQualificationGas: copyExpert.secondQualificationGas,
              isExpertOwner: copyExpert.isExpertOwner || copyThis.isExpertOwner,
              id: copyExpert.id,
            };
            let copyArray = [...formik.values.experts].filter((expert) => {
              return expert.id != copyThis.id && expert.id != copyExpert.id;
            });
            formik.setFieldValue(`experts`, copyArray.concat(newObject));
          }
        });
        setPopupOpen(false);
      } else {
        setPopupOpen(false);
      }
    }
  };
  const modalContent = () => {
    if (formik.values.experts && formik.values.experts[`${selectedRow}`])
      return (
        <div className="popup-content">
          {!edit && (
            <>
              <div className="popup-content__popup-field">
                <InputField
                  label={`Nachname*`}
                  name={`experts[${selectedRow}].lastName`}
                  key={`experts[${selectedRow}].lastName`}
                  id={`experts[${selectedRow}].lastName`}
                  value={formik.values.experts[`${selectedRow}`].lastName}
                  onChange={formik.handleChange}
                  fullWidth
                  disabled={edit}
                  oldValue={oldData[`${selectedRow}`] ? oldData[`${selectedRow}`].lastName : ''}
                  error={Boolean(
                    formik.errors.experts ? formik.errors.experts[`${selectedRow}`]?.lastName : ''
                  )}
                  helperText={
                    formik.errors.experts ? formik.errors.experts[`${selectedRow}`]?.lastName : ''
                  }></InputField>
              </div>
              <div className="popup-content__popup-field">
                <InputField
                  label={`Vorname*`}
                  name={`experts[${selectedRow}].firstName`}
                  key={`experts[${selectedRow}].firstName`}
                  id={`experts[${selectedRow}].firstName`}
                  value={formik.values.experts[`${selectedRow}`].firstName}
                  onChange={formik.handleChange}
                  fullWidth
                  oldValue={oldData[`${selectedRow}`] ? oldData[`${selectedRow}`].firstName : ''}
                  disabled={edit}
                  error={Boolean(
                    formik.errors.experts ? formik.errors.experts[`${selectedRow}`]?.firstName : ''
                  )}
                  helperText={
                    formik.errors.experts ? formik.errors.experts[`${selectedRow}`]?.firstName : ''
                  }></InputField>
              </div>
            </>
          )}
          {getTenantId() === TENANT_ID.BAG && !edit ? (
            <div className="popup-content__popup-field">
              <InputField
                label="Geburtsdatum*"
                name={`experts[${selectedRow}].birthdate`}
                key={`experts[${selectedRow}].birthdate`}
                id={`experts[${selectedRow}].birthdate`}
                value={formik.values.experts[`${selectedRow}`].birthdate}
                fullWidth
                oldValue={
                  oldData[`${selectedRow}`] ? oldData[`${selectedRow}`].birthdate ?? '' : ''
                }
                onChange={formik.handleChange}
                error={Boolean(
                  formik.errors.experts ? formik.errors.experts[`${selectedRow}`]?.birthdate : ''
                )}
                helperText={
                  formik.errors.experts ? formik.errors.experts[`${selectedRow}`]?.birthdate : ''
                }></InputField>
            </div>
          ) : null}
          {isGuest && (
            <div className="popup-content__popup-field">
              <InputField
                label={`Strom-Ausweis gültig bis*`}
                name={`experts[${selectedRow}].expertEnergyValidityDate`}
                key={`experts[${selectedRow}].expertEnergyValidityDate`}
                id={`experts[${selectedRow}].expertEnergyValidityDate`}
                value={formik.values.experts[`${selectedRow}`].expertEnergyValidityDate}
                fullWidth
                onChange={formik.handleChange}
                oldValue={
                  oldData[`${selectedRow}`]
                    ? oldData[`${selectedRow}`].expertEnergyValidityDate ?? ''
                    : ''
                }
                error={Boolean(
                  formik.errors.experts
                    ? formik.errors.experts[`${selectedRow}`]?.expertEnergyValidityDate
                    : ''
                )}
                helperText={
                  formik.errors.experts
                    ? formik.errors.experts[`${selectedRow}`]?.expertEnergyValidityDate
                    : ''
                }></InputField>
            </div>
          )}
          {!isGuest && (
            <>
              <div className="popup-content__popup-checboxes">
                <div className="flex-row">
                  <div>Die verantwortliche Fachkraft ist Inhaber/Geschäftsführer*?</div>
                  <LabelRadiobutton
                    label="Nein"
                    name={`experts[${selectedRow}].isExpertOwner`}
                    id={`experts[${selectedRow}].isExpertOwner`}
                    error={Boolean(
                      formik.errors.experts
                        ? formik.errors?.experts[`${selectedRow}`].isExpertOwner
                        : ''
                    )}
                    value={false}
                    checked={formik.values?.experts[`${selectedRow}`].isExpertOwner === false}
                    onChange={formik.handleChange}></LabelRadiobutton>
                  <LabelRadiobutton
                    label="Ja"
                    name={`experts[${selectedRow}].isExpertOwner`}
                    id={`experts[${selectedRow}].isExpertOwner`}
                    error={Boolean(
                      formik.errors.experts
                        ? formik.errors?.experts[`${selectedRow}`].isExpertOwner
                        : ''
                    )}
                    value={true}
                    checked={formik.values?.experts[`${selectedRow}`].isExpertOwner === true}
                    onChange={formik.handleChange}></LabelRadiobutton>
                </div>
              </div>
              <div className="popup-content__popup-field">
                <InputField
                  fullWidth
                  select
                  items={Object.keys(FIRST_QUALIFICATIONS)
                    .filter((qualificationsKey) =>
                      evaluateCondition(
                        FIRST_QUALIFICATIONS[qualificationsKey].whenShouldFieldRender,
                        { branch: BRANCH_TYPE.ENERGY }
                      )
                    )
                    .map((qualificationsKey) => FIRST_QUALIFICATIONS[qualificationsKey])}
                  label={'Qualifikation wählen*'}
                  onChange={formik.handleChange}
                  name={`experts[${selectedRow}].firstQualificationEnergy`}
                  id={`experts[${selectedRow}].firstQualificationEnergy`}
                  error={Boolean(
                    formik.errors.experts
                      ? formik.errors?.experts[`${selectedRow}`].firstQualificationEnergy
                      : ''
                  )}
                  value={formik.values?.experts[`${selectedRow}`].firstQualificationEnergy}
                  oldValue={''}></InputField>
              </div>
              {formik.values && formik.values.experts[`${selectedRow}`].firstQualificationEnergy
                ? (() => {
                    const secondQualficationsItems = Object.keys(SECOND_QUALIFICATIONS)
                      .filter((qualificationsKey) =>
                        evaluateCondition(
                          SECOND_QUALIFICATIONS[qualificationsKey].whenShouldFieldRender,
                          {
                            branch: BRANCH_TYPE.ENERGY,
                            firstQualificationEnergy:
                              formik.values.experts[`${selectedRow}`].firstQualificationEnergy,
                          }
                        )
                      )
                      .map((qualificationsKey) => SECOND_QUALIFICATIONS[qualificationsKey]);
                    if (secondQualficationsItems.length > 1) {
                      return (
                        <div className="popup-content__popup-field">
                          <InputField
                            fullWidth
                            select
                            items={secondQualficationsItems}
                            onChange={formik.handleChange}
                            label={'Qualifikation wählen*'}
                            name={`experts[${selectedRow}].secondQualificationEnergy`}
                            id={`experts[${selectedRow}].secondQualificationEnergy`}
                            error={Boolean(
                              formik.errors.experts
                                ? formik.errors?.experts[`${selectedRow}`].secondQualificationEnergy
                                : ''
                            )}
                            value={
                              formik.values?.experts[`${selectedRow}`].secondQualificationEnergy
                            }
                            oldValue={''}></InputField>
                        </div>
                      );
                    } else {
                      return (
                        <div className="popup-content__popup-field">
                          <div className="input-field">
                            <InfoBox>{secondQualficationsItems[0].label}</InfoBox>
                          </div>
                        </div>
                      );
                    }
                  })()
                : null}
            </>
          )}
        </div>
      );
    else {
      return <>Error</>;
    }
  };

  return (
    <CustomModal
      isOpen={popupOpen}
      title={edit ? 'Verlängerung' : 'Fachkraft hinzufügen - Strom'}
      ctaButtons={
        <>
          <ActionButton onClick={onSubmit} title="Ok"></ActionButton>
          <ActionButton inverted onClick={onCancel} title="Abbrechen"></ActionButton>
        </>
      }
      content={modalContent()}></CustomModal>
  );
}
ExpertPopupEnergy.propTypes = {
  data: PropTypes.array,
  oldData: PropTypes.array,
  formik: PropTypes.object,
  selectedRow: PropTypes.number,
  popupOpen: PropTypes.bool,
  setPopupOpen: PropTypes.func,
  edit: PropTypes.bool,
  isGuest: PropTypes.bool,
};
