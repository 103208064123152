import { BIRTHDATE, TENANT_ID, TEST_VALIDITY_DATE_GERMAN } from 'global/constants';

const baseFormData = {
  companyName1: 'Test',
  companyName2: 'Gmbh',
  companyName3: '',
  companyOwner: 'Test Owner',
  companyStreet: 'Test Street',
  companyHouseNumber: '12',
  companyPostal: '12121',
  companyCity: 'Teststadt',
  companyRegion: '',
  gridProviderEnergy: 'Strom Test provider',
  gridProviderGas: 'Gas Test provider',
  foreignCompanyIdEnergy: '12123sd12',
  foreignCompanyIdGas: '12123sd12',
  phone: '121231231',
  mobile: '',
  energyAgreement: true,
  gasAgreement: true,
  doesNeedAvaGuestPostal: false,
  avaGuestPostal: '',
};

const BAGMainFields = {
  craftsChamberArea: 'Schwaben',
  craftsChamberNumber: '98031',
  craftsChamberDate: '12.12.2000',
  businessRegistrationLocation: 'Testlocal',
  businessRegistrationDate: '12.12.2000',
  commercialRegisterNumber: '',
  commercialRegisterDate: '',
};

const BAGMainFieldsHilfsbetrieb = {};

const AVAGuestOnlyFields = {
  doesNeedAvaGuestPostal: true,
  avaGuestPostal: '12345',
};

const QUALIFICATIONS_ENERGY = {
  firstQualificationEnergy: 'masterTo1997Energy',
  secondQualificationEnergy: 'masterTo1997Electrician',
};
const QUALIFICATIONS_GAS = {
  firstQualificationGas: 'masterTo1997Gas',
  secondQualificationGas: 'masterTo1997Gas',
};

const EnergyMainNoOwner = (tenantId) => [
  {
    lastName: 'No Owner',
    firstName: 'Energy Main',
    isExpertEnergyActive: true,
    isExpertGasActive: false,
    birthdate: tenantId === TENANT_ID.BAG ? BIRTHDATE : undefined,
    energyValidityDate: '',
    gasValidityDate: '',
    isExpertOwner: false,
    id: 'EnergyMainNoOwner_1',
    ...QUALIFICATIONS_ENERGY,
  },
];

const EnergyMainOwner = (tenantId) => [
  {
    lastName: 'Owner',
    firstName: 'Energy Main',
    isExpertEnergyActive: true,
    isExpertGasActive: false,
    birthdate: tenantId === TENANT_ID.BAG ? BIRTHDATE : undefined,
    energyValidityDate: '',
    gasValidityDate: '',
    isExpertOwner: true,
    id: 'EnergyMainOwner_1',
    ...QUALIFICATIONS_ENERGY,
  },
];

const GasMainOwner = (tenantId) => [
  {
    lastName: 'Owner',
    firstName: 'Gas Main',
    isExpertEnergyActive: false,
    isExpertGasActive: true,
    birthdate: tenantId === TENANT_ID.BAG ? BIRTHDATE : undefined,
    energyValidityDate: '',
    gasValidityDate: '',
    isExpertOwner: true,
    id: 'GasMainOwner_1',
    ...QUALIFICATIONS_GAS,
  },
];
const GasMainNoOwner = (tenantId) => [
  {
    lastName: 'No Owner',
    firstName: 'Gas Main',
    isExpertEnergyActive: false,
    isExpertGasActive: true,
    birthdate: tenantId === TENANT_ID.BAG ? BIRTHDATE : undefined,
    energyValidityDate: '',
    gasValidityDate: '',
    isExpertOwner: false,
    id: 'GasMainNoOwner_1',
    ...QUALIFICATIONS_GAS,
  },
];
const EnergyGuest = (tenantId) => [
  {
    lastName: 'No Owner',
    firstName: 'Energy Guest',
    isExpertEnergyActive: true,
    isExpertGasActive: false,
    birthdate: tenantId === TENANT_ID.BAG ? BIRTHDATE : undefined,
    energyValidityDate: TEST_VALIDITY_DATE_GERMAN,
    gasValidityDate: '',
    id: 'EnergyGuest_1',
  },
];
const GasGuest = (tenantId) => [
  {
    lastName: 'No Owner',
    firstName: 'Gas Guest',
    isExpertEnergyActive: false,
    isExpertGasActive: true,
    birthdate: tenantId === TENANT_ID.BAG ? BIRTHDATE : undefined,
    energyValidityDate: '',
    gasValidityDate: TEST_VALIDITY_DATE_GERMAN,
    id: 'GasGuest_1',
  },
];
const EnergyMainGasMainNoOwnerMix = (tenantId) => [
  {
    lastName: 'No Owner',
    firstName: 'Energy Main Gas Main',
    isExpertEnergyActive: true,
    isExpertGasActive: true,
    birthdate: tenantId === TENANT_ID.BAG ? BIRTHDATE : undefined,
    energyValidityDate: '',
    gasValidityDate: '',
    isExpertOwner: false,
    id: 'EnergyMainGasMainNoOwner_1',
    ...QUALIFICATIONS_ENERGY,
    ...QUALIFICATIONS_GAS,
  },
];

const EnergyGuestGasMainNoOwnerMix = (tenantId) => [
  {
    lastName: 'No Owner',
    firstName: 'Energy Guest Gas Main',
    isExpertEnergyActive: true,
    isExpertGasActive: true,
    birthdate: tenantId === TENANT_ID.BAG ? BIRTHDATE : undefined,
    energyValidityDate: TEST_VALIDITY_DATE_GERMAN,
    gasValidityDate: '',
    isExpertOwner: false,
    id: 'EnergyGuestGasMainNoOwner_1',
    ...QUALIFICATIONS_GAS,
  },
];

const EnergyMainGasGuestNoOwnerMix = (tenantId) => [
  {
    lastName: 'No Owner',
    firstName: 'Energy Main Gas Guest',
    isExpertEnergyActive: true,
    isExpertGasActive: true,
    birthdate: tenantId === TENANT_ID.BAG ? BIRTHDATE : undefined,
    energyValidityDate: '',
    gasValidityDate: TEST_VALIDITY_DATE_GERMAN,
    isExpertOwner: false,
    id: 'EnergyMainGasGuestNoOwner_1',
    ...QUALIFICATIONS_ENERGY,
  },
];

const EnergyGuestGasGuestMix = (tenantId) => [
  {
    lastName: 'No Owner',
    firstName: 'Energy Guest Gas Guest',
    isExpertEnergyActive: true,
    isExpertGasActive: true,
    birthdate: tenantId === TENANT_ID.BAG ? BIRTHDATE : undefined,
    energyValidityDate: TEST_VALIDITY_DATE_GERMAN,
    gasValidityDate: TEST_VALIDITY_DATE_GERMAN,
    isExpertOwner: false,
    id: 'EnergyGuestGasGuest_1',
  },
];

const EnergyMain = (tenantId) => [...EnergyMainNoOwner(tenantId), ...EnergyMainOwner(tenantId)];
const GasMain = (tenantId) => [...GasMainNoOwner(tenantId), ...GasMainOwner(tenantId)];
const EnergyMainGasMain = (tenantId) => [
  ...EnergyMain(tenantId),
  ...GasMain(tenantId),
  ...EnergyMainGasMainNoOwnerMix(tenantId),
];
const EnergyGuestGasMain = (tenantId) => [
  ...EnergyGuest(tenantId),
  ...GasMain(tenantId),
  ...EnergyGuestGasMainNoOwnerMix(tenantId),
];
const EnergyMainGasGuest = (tenantId) => [
  ...EnergyMain(tenantId),
  ...GasGuest(tenantId),
  ...EnergyMainGasGuestNoOwnerMix(tenantId),
];
const EnergyGuestGasGuest = (tenantId) => [
  ...EnergyGuest(tenantId),
  ...GasGuest(tenantId),
  ...EnergyGuestGasGuestMix(tenantId),
];
const NE_FD = {
  base: { baseFormData, BAGMainFields, BAGMainFieldsHilfsbetrieb, AVAGuestOnlyFields },
  experts: {
    GasGuest,
    EnergyGuest,
    EnergyMain,
    GasMain,
    EnergyMainGasMain,
    EnergyGuestGasMain,
    EnergyMainGasGuest,
    EnergyGuestGasGuest,
  },
};

export { NE_FD };
