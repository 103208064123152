import React, { useContext, useMemo, useState } from 'react';
import { useFormik } from 'formik';

import './DebDeleteCompanyForm.sass';
import { MapTo } from '@adobe/aem-spa-component-mapping';
import { Utils } from '@adobe/aem-react-editable-components';
import { YDebDeleteCompanyFormSchema } from './YDebDeleteCompanyFormSchema';

import LoadingIndicator from 'components/LoadingIndicator/LoadingIndicator';
import { useCurrentInstaller } from 'hooks/UseCurrentInstaller';
import { PageTitle } from 'components/PageTitle/PageTitle';
import { InputField } from 'components/FormHelper/InputField';
import { ActionButton } from 'components/ActionButton/ActionButton';
import { useMutation } from '@apollo/client';
import { DELETE_COMPANY } from 'Queries/Mutations';
import { getMyDataPath } from 'utils/UrlUtil';
import { patchEinwilligungByInstallerId } from 'api/installer/PatchEinwilligungByInstallerId';
import { ErrorHandlingContext } from 'context/ErrorHandling';
import { RenderAemComponent } from 'components/RenderAemComponent/RenderAemComponent';
import { CheckboxRow } from 'components/CheckboxRow/CheckboxRow';
import { SubmitPopup } from 'components/FormHelper/SubmitPopup';

export const DebDeleteCompanyForm = () => {
  const { installer } = useCurrentInstaller();
  const isEditor = Utils.isInEditor();
  const [deleteCompanyMutation] = useMutation(DELETE_COMPANY);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [openPopup, setOpenPopup] = useState(false);
  const [response, setResponse] = useState('');

  const { setShowError } = useContext(ErrorHandlingContext);

  if (isEditor) {
    return <RenderAemComponent componentProps={{}} name="DebDeleteCompanyForm" />;
  }
  const submitForm = async (values) => {
    setIsSubmitting(true);
    try {
      const patchData = {
        zu_adressveroeff: 'Nein',
        zu_infoversand: 'Nein',
      };

      const response = await patchEinwilligungByInstallerId(installer.id, patchData, setShowError);

      if (response.errors) {
        throw new Error(response.errors);
      }
    } catch (e) {
      console.log(e);
    }
    deleteCompanyMutation({
      variables: {
        deleteCompanyInput: {
          baseDataEntries: [
            { first: 'Email', second: installer.email },
            { first: 'Eintragungsnummer', second: installer.id },
          ],
          deleteReason: values.deleteReason || '-',
          deletedFromDate: values.deleteDate || '-',
        },
      },
    })
      .then(({ data }) => {
        setResponse(data.deleteCompany.status);
        setIsSubmitting(false);
        setOpenPopup(true);
      })
      .catch(() => {
        setResponse('ERROR');
        setOpenPopup(true);
        setIsSubmitting(false);
      });
  };
  const formik = useFormik({
    initialValues: {
      deletedCheckbox: false,
      deleteDate: '',
      deleteReason: '',
    },
    validationSchema: YDebDeleteCompanyFormSchema,
    enableReinitialize: true,
    onSubmit: submitForm,
    validateOnChange: true,
    validateOnMount: true,
  });

  const hasErrors = useMemo(() => {
    return Object.keys(formik.errors).length > 0;
  }, [formik.values, formik.errors]);

  return (
    <div className="deb-delete-company-form">
      <div className="deb-delete-company-form__container">
        {isEditor ? (
          <div>DEB - Delete company form</div>
        ) : formik.values ? (
          <form
            className="deb-delete-company__container"
            onSubmit={(e) => {
              e.preventDefault();
              formik.handleSubmit();
            }}>
            <PageTitle text="Firmeneintrag löschen"> </PageTitle>
            <div>
              Ihre Daten im Installateurverzeichnis werden nach der gesetzlichen Aufbewahrungsfrist
              endgültig aus dem System gelöscht.
            </div>
            <br />
            <div className="deb-delete-company__hero">
              Sobald Ihr Eintrag im Installateurverzeichnis gelöscht wurde, haben Sie keinen Zugriff
              mehr auf das Informationsportal e-fix (
              <a href="https://www.e-fix.info/">https://www.e-fix.info/</a>) und das
              meinAuftragsportal (mAP) ebenso wie die Systeme der Schwesterunternehmen.
              <br />
              Das beinhaltet Zugang zu den folgenden Portalen der Netzbetreiber:
            </div>
            <div className="deb-delete-company__table">
              <div className="deb-delete-company__table__row">
                <div className="deb-delete-company__table__row__item">Avacon Netz GmbH:</div>
                <div className="deb-delete-company__table__row__item__link">
                  <a href="https://www.avacon-netz.de/de/meinauftragsportal.html">
                    https://www.avacon-netz.de/de/meinauftragsportal.html
                  </a>
                </div>
              </div>
              <div className="deb-delete-company__table__row">
                <div className="deb-delete-company__table__row__item">Bayernwerk Netz GmbH: </div>
                <div className="deb-delete-company__table__row__item__link">
                  <a href="https://www.bayernwerk-netz.de/de/meinauftragsportal.html">
                    https://www.bayernwerk-netz.de/de/meinauftragsportal.html
                  </a>
                </div>
              </div>
              <div className="deb-delete-company__table__row">
                <div className="deb-delete-company__table__row__item">E.DIS Netz GmbH: </div>
                <div className="deb-delete-company__table__row__item__link">
                  <a href="https://www.e-dis-netz.de/de/meinauftragsportal.html">
                    https://www.e-dis-netz.de/de/meinauftragsportal.html
                  </a>
                </div>
              </div>
              <div className="deb-delete-company__table__row">
                <div className="deb-delete-company__table__row__item">ElbEnergie GmbH: </div>
                <div className="deb-delete-company__table__row__item__link">
                  <a href="https://www.elbenergie.com/de/meinauftragsportal.html">
                    https://www.elbenergie.com/de/meinauftragsportal.html
                  </a>
                </div>
              </div>
              <div className="deb-delete-company__table__row">
                <div className="deb-delete-company__table__row__item">HanseGas GmbH: </div>
                <div className="deb-delete-company__table__row__item__link">
                  <a href="https://www.hansegas.com/de/meinauftragsportal.html">
                    https://www.hansegas.com/de/meinauftragsportal.html
                  </a>
                </div>
              </div>
              <div className="deb-delete-company__table__row">
                <div className="deb-delete-company__table__row__item">NordNetz GmbH: </div>
                <div className="deb-delete-company__table__row__item__link">
                  <a href="https://www.sh-netz.com/de/meinauftragsportal.html">
                    https://www.sh-netz.com/de/meinauftragsportal.html
                  </a>
                </div>
              </div>
              <div className="deb-delete-company__table__row">
                <div className="deb-delete-company__table__row__item">
                  Schleswig-Holstein Netz GmbH:
                </div>
                <div className="deb-delete-company__table__row__item__link">
                  <a href="https://www.sh-netz.com/de/meinauftragsportal.html">
                    https://www.sh-netz.com/de/meinauftragsportal.html
                  </a>
                </div>
              </div>
            </div>
            <div className="deb-delete-company__inputs">
              <CheckboxRow
                name="deletedCheckbox"
                onChange={formik.handleChange}
                value={formik.values.deletedCheckbox}
                error={Boolean(formik.errors.deletedCheckbox)}
                oldValue="false"
                helperText={formik.errors.deletedCheckbox || ''}
                label="Ich möchte meinen Eintrag in den oben genannten Verzeichnissen löschen"></CheckboxRow>
              <InputField
                label="Grund der Löschung"
                name="deleteReason"
                oldValue=""
                error={Boolean(formik.errors.deleteReason)}
                helperText={formik.errors.deleteReason || ''}
                onChange={formik.handleChange}
                value={formik.values.deleteReason}
                fullWidth></InputField>
              <InputField
                oldValue=""
                label="Löschung gültig ab"
                error={Boolean(formik.errors.deleteDate)}
                helperText={formik.errors.deleteDate || ''}
                value={formik.values.deleteDate}
                name="deleteDate"
                onChange={formik.handleChange}
                fullWidth></InputField>
            </div>
            <ActionButton
              disabled={hasErrors}
              inverted
              title="Firma löschen"
              type="submit"></ActionButton>
          </form>
        ) : (
          <LoadingIndicator />
        )}
      </div>
      <SubmitPopup
        type="DELETE_COMPANY"
        loading={isSubmitting}
        popupOpen={openPopup}
        setPopupOpen={() => setOpenPopup(false)}
        response={response}
        redirectLinkOnSuccessOrCancel={getMyDataPath()}
        onErrorRetry={() => {
          submitForm();
        }}></SubmitPopup>
    </div>
  );
};

DebDeleteCompanyForm.displayName = 'DebDeleteCompanyForm';

DebDeleteCompanyForm.propTypes = {};

DebDeleteCompanyForm.defaultProps = {};

MapTo(['e-fix/components/content/deleteCompany'])(DebDeleteCompanyForm);
