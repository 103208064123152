import { getExpertsByInstallerId } from 'api/installer/GetExpertsByInstallerId';
import {
  DEB_CURRENT_TEST_SUITE,
  shouldFillDebTestData,
} from 'components/DEBForm/Utils/DebTestSetup';
import { ErrorHandlingContext } from 'context/ErrorHandling';
import { useContext, useEffect, useState } from 'react';

export const useCurrentExperts = (installerId) => {
  const [experts, setExperts] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const { setShowError } = useContext(ErrorHandlingContext);

  useEffect(() => {
    fetchExperts();
  }, [installerId]);

  const fetchExperts = async () => {
    if (installerId) {
      setIsLoading(true);
      try {
        let responseExperts = {};
        if (shouldFillDebTestData) {
          responseExperts = DEB_CURRENT_TEST_SUITE.installerData.experts;
        } else {
          responseExperts = await getExpertsByInstallerId(installerId, setShowError);
        }
        setExperts(responseExperts);
        setIsLoading(false);
      } catch (e) {
        console.log('useCurrentExperts info error', e, installerId);
      } finally {
        setIsLoading(false);
      }
    }
  };

  return { experts, isLoading, refetch: fetchExperts };
};
