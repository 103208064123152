import { InputField } from 'components/FormHelper/InputField';
import { LabelCheckbox } from 'components/LabelCheckbox/LabelCheckbox';
import { DEB_ACTIVE_PAGE, testSuitesKeys } from 'global/constants';
import React from 'react';
import './FormTestingBanner.sass';
import { isProduction } from 'utils/StageUtils';
import {
  DEB_SELECTED_TEST_SUITE,
  shouldFillDebTestData,
} from 'components/DEBForm/Utils/DebTestSetup';
import PropTypes from 'prop-types';
function DebFormTestingBanner({ activePage }) {
  const toggleTestSetup = () => {
    localStorage.setItem('FILL_DEB_FORM', !shouldFillDebTestData);

    if (!localStorage.getItem('DEB_SELECTED_TEST_SUITE')) {
      localStorage.setItem('DEB_SELECTED_TEST_SUITE', 'GuestEnergyNonH');
    }
    window.location.reload();
  };
  console.log('DEB_SELECTED_TEST_SUITE', DEB_SELECTED_TEST_SUITE);
  const changeTestingAccount = (e) => {
    localStorage.setItem('DEB_SELECTED_TEST_SUITE', e.target.value);
    window.location.reload();
  };

  const DebTestingAccounts = () => {
    return testSuitesKeys.map((key) => {
      return {
        label: key,
        value: key,
      };
    });
  };

  console.log('activePage', activePage);
  if (isProduction()) {
    return null;
  } else
    return (
      <div>
        {activePage === DEB_ACTIVE_PAGE.FORM_PAGE ? (
          <>
            <div className="test-alert">
              <LabelCheckbox
                name={'testSetup'}
                value={shouldFillDebTestData}
                onChange={toggleTestSetup}
                label={'Testdaten laden?'}></LabelCheckbox>
              {shouldFillDebTestData ? (
                <InputField
                  fullWidth
                  select
                  items={DebTestingAccounts()}
                  label={'Testfall wählen'}
                  onChange={changeTestingAccount}
                  name={`selectedTestAccount`}
                  id={`selectedTestAccount`}
                  value={DEB_SELECTED_TEST_SUITE}
                  oldValue={''}></InputField>
              ) : null}
            </div>
          </>
        ) : shouldFillDebTestData ? (
          <div className="test-alert">
            <div>
              <span className="test-alert-bold">Geladene Testdaten: </span>{' '}
              {DEB_SELECTED_TEST_SUITE}
            </div>
            <div>
              <span className="test-alert-bold">Bitte beachten: </span> Änderungen können durch den
              Tester vorgenommen werden
            </div>
          </div>
        ) : null}
      </div>
    );
}
DebFormTestingBanner.propTypes = {
  activePage: PropTypes.string,
};
export default DebFormTestingBanner;
