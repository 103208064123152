import React, { useEffect, useState } from 'react';
import './NEStepForm.sass';
import { useNESteps } from 'context/NEStepsContext';

import { FormActionButtons } from 'components/FormHelper/FormActionButtons';
import { NEFormPage } from './NEFormPage';
import PropTypes from 'prop-types';
import { ERRORS } from 'components/DEBForm/Utils/Errors';
import { CURRENT_TEST_SUITE } from 'components/NEForm/NETestdata/NETestSetup';
import { NE_ACTIVE_STEP } from 'global/constants';

const NEStepForm = ({ energyAgreementLink, gasAgreementLink }) => {
  const { shouldFillNETestData, activeStep, setActiveStep, setSubmittedValuesWithStep } =
    useNESteps();
  const [buttonErrorMessage, setButtonErrorMessage] = useState(ERRORS.DEFAULT);

  useEffect(() => {
    if (shouldFillNETestData) {
      setSubmittedValuesWithStep(NE_ACTIVE_STEP.EMAIL_INPUT, CURRENT_TEST_SUITE.step0);
      setSubmittedValuesWithStep(NE_ACTIVE_STEP.BRANCH_SELECTION, CURRENT_TEST_SUITE.step1);
    }
  }, []);

  if (activeStep !== NE_ACTIVE_STEP.FORM) return null;

  const onBack = () => {
    setActiveStep(NE_ACTIVE_STEP.BRANCH_SELECTION);
  };

  const onSubmit = () => {
    setActiveStep(NE_ACTIVE_STEP.FORM_UPLOAD);
  };

  return (
    <>
      <div className="ne-step-form">
        <NEFormPage
          energyAgreementLink={energyAgreementLink}
          gasAgreementLink={gasAgreementLink}
          setButtonErrorMessage={setButtonErrorMessage}
        />
      </div>
      <div className="ne-form__action-buttons">
        <FormActionButtons
          hasErrors={Boolean(buttonErrorMessage)}
          titleLeft="Zurück"
          titleRight={activeStep === NE_ACTIVE_STEP.FORM_UPLOAD ? 'Eintrag einreichen' : 'Weiter'}
          onClickLeft={onBack}
          onClickRight={onSubmit}
          errorMessage={buttonErrorMessage}
        />
      </div>
    </>
  );
};

NEStepForm.propTypes = {
  energyAgreementLink: PropTypes.string,
  gasAgreementLink: PropTypes.string,
};

export { NEStepForm };
