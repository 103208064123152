import React from 'react';
import './NEStepsContainer.sass';
import { NEStepsContext } from 'context/NEStepsContext';
import PropTypes from 'prop-types';
import { PageTitleSection } from 'components/PageTitleSection/PageTitleSection';
import { NEStepsbar } from '../NEStepsbar/NEStepsbar';
import NEFormTestingBanner from 'components/FormHelper/NEFormTestingBanner';

const NEStepsContainer = ({ children }) => {
  return (
    <NEStepsContext>
      <div className="ne-steps-container">
        <NEFormTestingBanner></NEFormTestingBanner>
        <PageTitleSection title="Eintragung ins Installateur-Verzeichnis" notFramed />

        <NEStepsbar />
        <>{children}</>
      </div>
    </NEStepsContext>
  );
};

NEStepsContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

export { NEStepsContainer };
