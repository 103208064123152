import React from 'react';
import { ThemedTextField } from 'components/ThemedTextField/ThemedTextField';
import PropTypes from 'prop-types';
import './InputField.sass';
import { MenuItem } from '@material-ui/core';

export function InputField(props) {
  const { oldValue, infoText, items, ...filteredProps } = props;
  return (
    <div className={`input-field ${props.multiline ? 'input-field__full-width' : ''}`}>
      <ThemedTextField
        className={
          props.error
            ? 'input-field__error'
            : oldValue && oldValue != props.value
            ? 'input-field__edited'
            : 'input-field__normal'
        }
        {...filteredProps}>
        {props.select
          ? items.map((item) => {
              return (
                <MenuItem
                  // className={'input-field__select-menu-items'}
                  value={item.value}
                  key={item.value}>
                  {item.label}
                </MenuItem>
              );
            })
          : null}
      </ThemedTextField>
      {infoText && (
        <div
          className={`input-field__info-text ${
            props.disabled ? 'input-field__info-text__disabled' : ''
          }`}>
          {infoText}
        </div>
      )}
    </div>
  );
}
InputField.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object),
  oldValue: PropTypes.string,
  value: PropTypes.string,
  infoText: PropTypes.string,
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  select: PropTypes.bool,
  fullWidth: PropTypes.bool,
  multiline: PropTypes.bool,
};
