import { BRANCH_TYPE, TENANT_ID } from 'global/constants';
import { NE_EI } from './EmailInput';
import { NE_FD } from './FormData';
import { isInstallerRegisteringForBranch, isRegisteringBranchGuest } from 'utils/InstallerUtils';

const getFDExperts = (isEnergyActive, isGasActive, isEnergyGuest, isGasGuest) => {
  if (!isEnergyActive && !isGasActive) return [];

  if (isEnergyActive && isGasActive) {
    const key = `Energy${isEnergyGuest ? 'Guest' : 'Main'}Gas${isGasGuest ? 'Guest' : 'Main'}`;
    return NE_FD.experts[key];
  }

  if (isEnergyActive) {
    return NE_FD.experts[`Energy${isEnergyGuest ? 'Guest' : 'Main'}`];
  }

  return NE_FD.experts[`Gas${isGasGuest ? 'Guest' : 'Main'}`];
};

export const getFormDataConfigs = (tenantId, branchSelectionValues) => {
  const isEnergyActive = isInstallerRegisteringForBranch(BRANCH_TYPE.ENERGY, branchSelectionValues);
  const isGasActive = isInstallerRegisteringForBranch(BRANCH_TYPE.GAS, branchSelectionValues);
  const isEnergyGuest = isRegisteringBranchGuest(BRANCH_TYPE.ENERGY, branchSelectionValues);
  const isGasGuest = isRegisteringBranchGuest(BRANCH_TYPE.GAS, branchSelectionValues);
  const hasEntryGuest = isEnergyGuest || isGasGuest;

  let experts = getFDExperts(isEnergyActive, isGasActive, isEnergyGuest, isGasGuest);

  return {
    ...NE_FD.base.baseFormData,
    ...(tenantId === TENANT_ID.BAG ? NE_FD.base.BAGMainFields : {}),
    ...(tenantId === TENANT_ID.AVA && hasEntryGuest ? NE_FD.base.AVAGuestOnlyFields : {}),
    experts: experts(tenantId),
    ...NE_EI,
  };
};
