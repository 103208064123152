import {
  always,
  isRegisteringExpertOwner,
  never,
  onlyDEB,
  onlyHilfsbetrieb,
  onlyNE,
  VALIDATION_CONDITIONS,
} from 'global/renderingValidationConstants';
import React from 'react';

export const DOCUMENT_NAME_TO_ID = {
  businessRegistrationChange: 13,
  liabilityInsuranceProof: 14,
  businessEquipmentConfirmation: 15,
  installerContract: 16,
  craftCard: 17,
  commercialRegisterExtract: 18,
  gridOperatorRegistration: 19,
  employmentProof: 20,
  installerContractGas: 21,
  trainingCertificateExtension: 22,
  employmentProofResponsible: 23,
  installerContractGasResponsible: 24,
  qualificationProofElectricity: 25,
  qualificationProofGas: 26,
  treiCertificateElectricity: 27,
  trgiCertificate2018: 28,
  trgiCertificate100h: 29,
  installerContractMeterEnergy: 30,
  installerContractMeterGas: 31,
  installerContractSealing: 32,

  masterCertificateElectrical: 70,
  masterCertificateAppendix: 71,
  safetyCertificate: 72,
  masterCertificate: 73,
  masterCertificateAppendix2: 74,
  specificProfessionalPracticeProof: 75,
  technicianDiploma: 76,
  securityAndMaintenanceCertificateGas: 77,
};

export const SHARED_DOCUMENTS = {
  [DOCUMENT_NAME_TO_ID.businessRegistrationChange]: {
    id: DOCUMENT_NAME_TO_ID.businessRegistrationChange,
    title: 'Gewerbean-/ummeldung',
    name: 'businessRegistrationChange',
    isOptional: onlyHilfsbetrieb,
    uploadName: 'Date_Gewerbeanmeldung',
  },
  [DOCUMENT_NAME_TO_ID.liabilityInsuranceProof]: {
    id: DOCUMENT_NAME_TO_ID.liabilityInsuranceProof,
    title: 'Nachweis über das Bestehen einer Betriebshaftpflichtversicherung',
    infoText: 'Mit ausgewiesenen Tätigkeitsfeld: Strom / Gas',
    name: 'liabilityInsuranceProof',
    isOptional: never,
    uploadName: 'Date_Versicherung',
  },
  [DOCUMENT_NAME_TO_ID.businessEquipmentConfirmation]: {
    id: DOCUMENT_NAME_TO_ID.businessEquipmentConfirmation,
    title:
      'Selbstbestätigung Betriebsausstattung (mit Unterschrift der verantwortlichen Fachkraft)',
    name: 'businessEquipmentConfirmation',
    infoText: <>Eine Vorlage dazu finden Sie in diesem Dokument</>,
    isOptional: never,
    uploadName: 'Date_Betriebsausstattung',
  },
  [DOCUMENT_NAME_TO_ID.installerContract]: {
    id: DOCUMENT_NAME_TO_ID.installerContract,
    title: 'Installateurvertrag',
    name: 'installerContract',
    infoText: <>Hier finden Sie eine passende Vorlage</>,
    isOptional: never,
    uploadName: 'Date_Installateurvertrag_Gas',
  },
  [DOCUMENT_NAME_TO_ID.craftCard]: {
    id: DOCUMENT_NAME_TO_ID.craftCard,
    title: 'Handwerkskarte',
    name: 'craftCard',
    infoText: <>(Vorder- und Rückseite – im Scheckkartenformat)</>,
    whenShouldFieldRender: {
      type: VALIDATION_CONDITIONS.AND,
      conditions: [{ type: VALIDATION_CONDITIONS.NOT, condition: onlyHilfsbetrieb }],
    },
    uploadName: 'Date_HWK',
  },
  [DOCUMENT_NAME_TO_ID.commercialRegisterExtract]: {
    id: DOCUMENT_NAME_TO_ID.commercialRegisterExtract,
    title: 'Handelsregisterauszug',
    name: 'commercialRegisterExtract',
    optional: false,
    isOptional: {
      type: VALIDATION_CONDITIONS.OR,
      conditions: [onlyHilfsbetrieb, onlyNE],
    },
    uploadName: 'Date_HRA',
  },
  [DOCUMENT_NAME_TO_ID.gridOperatorRegistration]: {
    id: DOCUMENT_NAME_TO_ID.gridOperatorRegistration,
    title: 'Eintragung bei einem anderen Netzbetreiber (Installateurausweis)',
    name: 'gridOperatorRegistration',
    isOptional: never,
    uploadName: 'Date_installateurnummer_Gastausweis',
    infoText: (
      <>
        Bitte laden Sie Ihren aktuellen Installateurausweis hoch. Falls Sie keinen aktuellen Ausweis
        mit Anschrift haben, laden Sie Ihren alten Ausweis hoch, um den Antrag abzusenden. Reichen
        Sie zeitnah Ihren Ausweis nach.
      </>
    ),
    infoTextNE: <>Bitte laden Sie Ihren aktuellen Installateurausweis hoch.</>,
  },
  [DOCUMENT_NAME_TO_ID.employmentProof]: {
    id: DOCUMENT_NAME_TO_ID.employmentProof,
    title: 'Anstellungsnachweis der verantwortlichen Fachkraft',
    name: 'employmentProof',
    infoText: (
      <>
        z. B. mittels Anstellungsvertrag, Auszug Sozialversicherung, Bestätigung des
        Betriebsinhabers - von Ihrer verantwortlichen Fachkraft / Ihren verantwortlichen
        Fachkräften, wenn diese nicht gleichzeitig Inhaber bzw. Geschäftsführer der Firma ist / sind
      </>
    ),
    isOptional: never,
    uploadName: 'Date_Anstellungsnachweis',
  },
  [DOCUMENT_NAME_TO_ID.installerContractGas]: {
    id: DOCUMENT_NAME_TO_ID.installerContractGas,
    title: 'Installateurvertrag mit Unterschrift der verantwortlichen Fachkraft/Fachkräfte (Gas)',
    name: 'installerContractGas',
    infoText: <>Eine Vorlage dazu finden Sie in diesem Dokument</>,
    isOptional: never,
    uploadName: 'Date_Installateurvertrag_Gas_Fachkraft',
  },
  [DOCUMENT_NAME_TO_ID.trainingCertificateExtension]: {
    id: DOCUMENT_NAME_TO_ID.trainingCertificateExtension,
    title: 'Fortbildungsnachweis zur Ausweisverlängerung',
    infoText:
      'z.B. Schulungen zu DIN-Normen, VDE-Bestimmungen, VDE-Anwendungsregeln und/oder den TAB - bitte für jede Fachkraft hochladen',
    name: 'trainingCertificateExtension',
    isOptional: never,
    uploadName: 'Date_Fortbildungsnachweis',
  },

  [DOCUMENT_NAME_TO_ID.installerContractMeterEnergy]: {
    id: DOCUMENT_NAME_TO_ID.installerContractMeterEnergy,
    title: 'Vertrag  "Zählermontage durch Installateure" Strom',
    name: 'installerContractMeterEnergy',
    infoText: <>Eine Vorlage dazu finden Sie in diesem Dokument</>,
    isOptional: never,
    whenShouldFieldRender: {
      type: VALIDATION_CONDITIONS.OR,
      conditions: [
        {
          type: VALIDATION_CONDITIONS.AND,
          conditions: [{ type: VALIDATION_CONDITIONS.NOT, condition: onlyHilfsbetrieb }, onlyNE],
        },
        onlyDEB,
      ],
    },
    uploadName: 'Date_Vertrag_ZDI_Strom',
  },
  [DOCUMENT_NAME_TO_ID.installerContractMeterGas]: {
    id: DOCUMENT_NAME_TO_ID.installerContractMeterGas,
    title: 'Vertrag  "Zählermontage durch Installateure" Gas',
    name: 'installerContractMeterGas',
    infoText: <>Eine Vorlage dazu finden Sie in diesem Dokument</>,
    isOptional: never,
    whenShouldFieldRender: {
      type: VALIDATION_CONDITIONS.OR,
      conditions: [
        {
          type: VALIDATION_CONDITIONS.AND,
          conditions: [{ type: VALIDATION_CONDITIONS.NOT, condition: onlyHilfsbetrieb }, onlyNE],
        },
        onlyDEB,
      ],
    },
    uploadName: 'Date_Vertrag_ZDI_Gas',
  },
  [DOCUMENT_NAME_TO_ID.installerContractSealing]: {
    id: DOCUMENT_NAME_TO_ID.installerContractSealing,
    title: 'Vertrag "Nachplombierung"',
    name: 'installerContractSealing',
    infoText: <>Eine Vorlage dazu finden Sie in diesem Dokument</>,
    isOptional: never,
    whenShouldFieldRender: {
      type: VALIDATION_CONDITIONS.OR,
      conditions: [
        {
          type: VALIDATION_CONDITIONS.AND,
          conditions: [{ type: VALIDATION_CONDITIONS.NOT, condition: onlyHilfsbetrieb }, onlyNE],
        },
        onlyDEB,
      ],
    },
    uploadName: 'Date_Vertrag_Nachplombierung',
  },

  //experts documents
  [DOCUMENT_NAME_TO_ID.employmentProofResponsible]: {
    id: DOCUMENT_NAME_TO_ID.employmentProofResponsible,
    title: 'Anstellungsnachweis der verantwortlichen Fachkraft',
    name: 'employmentProofResponsible',
    infoText: (
      <>
        z. B. mittels Anstellungsvertrag, Auszug Sozialversicherung, Bestätigung des
        Betriebsinhabers - von Ihrer verantwortlichen Fachkraft / Ihren verantwortlichen
        Fachkräften, wenn diese nicht gleichzeitig Inhaber bzw. Geschäftsführer der Firma ist / sind
      </>
    ),
    isOptional: never,
    whenShouldFieldRender: {
      type: VALIDATION_CONDITIONS.OR,
      conditions: [
        {
          type: VALIDATION_CONDITIONS.AND,
          conditions: [
            { type: VALIDATION_CONDITIONS.NOT, condition: isRegisteringExpertOwner },
            onlyNE,
          ],
        },
        onlyDEB,
      ],
    },
    uploadName: 'Date_Anstellungsnachweis_firstname_lastname',
  },
  [DOCUMENT_NAME_TO_ID.installerContractGasResponsible]: {
    id: DOCUMENT_NAME_TO_ID.installerContractGasResponsible,
    title: 'Installateurvertrag mit Unterschrift der verantwortlichen Fachkraft/Fachkräfte (Gas)',
    name: 'installerContractGasResponsible',
    infoText: <>Eine Vorlage dazu finden Sie in diesem Dokument</>,
    isOptional: never,
    uploadName: 'Date_Installateurvertrag_Gas_Fachkraft',
  },
  [DOCUMENT_NAME_TO_ID.qualificationProofElectricity]: {
    id: DOCUMENT_NAME_TO_ID.qualificationProofElectricity,
    title: 'Qualifikationsnachweis(e) Strom',
    name: 'qualificationProofElectricity',
    isOptional: never,
    infoText: 'Hilfestellung dazu finden Sie in diesem Dokument',
    uploadName: 'Date_Quali_Strom_firstname_lastname',
  },
  [DOCUMENT_NAME_TO_ID.qualificationProofGas]: {
    id: DOCUMENT_NAME_TO_ID.qualificationProofGas,
    title: 'Qualifikationsnachweis(e) Gas',
    name: 'qualificationProofGas',
    isOptional: never,
    infoText: 'Hilfestellung dazu finden Sie in diesem Dokument',
    uploadName: 'Date_Quali_Gas_firstname_lastname',
  },
  [DOCUMENT_NAME_TO_ID.treiCertificateElectricity]: {
    id: DOCUMENT_NAME_TO_ID.treiCertificateElectricity,
    title:
      'Sachkundennachweis für den Anschluss elektrischer Anlagen und Geräte an das Niederspannungsnetz (Technische Regeln Elektro-Installationen; TREI)',
    name: 'treiCertificateElectricity',
    infoText: 'Wenn vorhanden, bitte mitsenden, sofern nicht in Meisterprüfung enthalten.',
    isOptional: never,
    uploadName: 'Date_TREI_firstname_lastname',
  },
  [DOCUMENT_NAME_TO_ID.trgiCertificate2018]: {
    id: DOCUMENT_NAME_TO_ID.trgiCertificate2018,
    title:
      'Sachkundennachweis TRGI 2018: 1 Tageslehrgang für Fackkraft Qualifikation von 2018 (wird individuell geprüft und ggf. nachgefordert)',
    name: 'trgiCertificate2018',
    infoText: 'Wenn vorhanden, bitte mitsenden, sofern nicht in Meisterprüfung enthalten.',
    isOptional: always,
    uploadName: 'Date_TRGI_firstname_lastname',
  },
  [DOCUMENT_NAME_TO_ID.trgiCertificate100h]: {
    id: DOCUMENT_NAME_TO_ID.trgiCertificate100h,
    title: 'Sachkundennachweis TRGI (100 Std.) / TRWI (80 Std.) (oder gleichwertig)',
    name: 'trgiCertificate100h',
    infoText: 'Wenn vorhanden, bitte mitsenden, sofern nicht in Meisterprüfung enthalten.',
    isOptional: never,
    uploadName: 'Date_TRGI_100h_firstname_lastname',
  },

  [DOCUMENT_NAME_TO_ID.masterCertificateElectrical]: {
    id: DOCUMENT_NAME_TO_ID.masterCertificateElectrical,
    title: 'Meisterprüfungszeugnis in einem Elektrohandwerk',
    name: 'masterCertificateElectrical',
    isOptional: never,
    uploadName: 'Date_Meisterpruefungszeugnis_Elektro',
  },
  //new fields
  [DOCUMENT_NAME_TO_ID.masterCertificateAppendix]: {
    id: DOCUMENT_NAME_TO_ID.masterCertificateAppendix,
    title:
      'Anhang zum Meisterprüfungszeugnis (Meisterprüfung nach welchem Meisterprüfungsverordnung abgelegt)',
    name: 'masterCertificateAppendix',
    isOptional: never,
    uploadName: 'Date_Anhang_Meisterpruefungszeugnis',
  },
  [DOCUMENT_NAME_TO_ID.safetyCertificate]: {
    id: DOCUMENT_NAME_TO_ID.safetyCertificate,
    title:
      'Bescheinigung nach §7 Abs. 6 ElektroTechMSTRV bzw. §6Abs. 6 InformationsTechMstrV oder ElektroMbMstrV (Sicherheitsschein)',
    name: 'safetyCertificate',
    isOptional: never,
    uploadName: 'Date_Sicherheitsschein',
  },
  [DOCUMENT_NAME_TO_ID.masterCertificate]: {
    id: DOCUMENT_NAME_TO_ID.masterCertificate,
    title: 'Meisterprüfungszeugnis Sparte Gas',
    name: 'masterCertificate',
    isOptional: never,
    uploadName: 'Date_Meisterpruefungszeugnis',
  },
  [DOCUMENT_NAME_TO_ID.masterCertificateAppendix2]: {
    id: DOCUMENT_NAME_TO_ID.masterCertificateAppendix2,
    title: 'Anhang zum Meisterprüfungszeugnis',
    name: 'masterCertificateAppendix2',
    isOptional: never,
    uploadName: 'Date_Anhang_Meisterpruefungszeugnis_2',
  },
  [DOCUMENT_NAME_TO_ID.specificProfessionalPracticeProof]: {
    id: DOCUMENT_NAME_TO_ID.specificProfessionalPracticeProof,
    title: 'Nachweis von fachspezifischer Berufspraxis gemäß TRGI',
    name: 'specificProfessionalPracticeProof',
    isOptional: never,
    uploadName: 'Date_Berufspraxis_Nachweis',
  },
  [DOCUMENT_NAME_TO_ID.technicianDiploma]: {
    id: DOCUMENT_NAME_TO_ID.technicianDiploma,
    title: 'Techniker-/Diplomurkunde',
    name: 'technicianDiploma',
    isOptional: never,
    uploadName: 'Date_Techniker_Diplomurkunde',
  },
  [DOCUMENT_NAME_TO_ID.securityAndMaintenanceCertificateGas]: {
    id: DOCUMENT_NAME_TO_ID.securityAndMaintenanceCertificateGas,
    title: 'Bescheinigung zum Fach Sicherheits- und Instandhaltungstechnik (Gas)',
    name: 'securityAndMaintenanceCertificateGas',
    isOptional: never,
    uploadName: 'Date_Gas-Besch_SuI-Technik_lastname',
  },
};
